import {FC, HTMLAttributes} from 'react'
import styles from './Button.module.scss'

interface IButtonProps extends HTMLAttributes<HTMLButtonElement>{
    disabled?: boolean
}

const Button: FC<IButtonProps> = ({children, className, ...props}) => {
    return (
        <button {...props} className={`${styles.button} ${className}`}>{children}</button>
    )

}

export default Button
