import {Chain, Token} from "../../index";
import styles from './ChainPaymentMethod.module.scss';
import CopyBlock from "../CopyBlock/CopyBlock";
import {FC} from "react";

interface IChainPaymentMethodProps {
    chain?: string;
    chainIconUrl: string;
    tokenIconUrl: string;
    token?: string,
    merchantAddress?: string;
    onDoubleClick?: () => void;
}

const ChainPaymentMethod:FC<IChainPaymentMethodProps> = ({chain, chainIconUrl, token, tokenIconUrl, merchantAddress, onDoubleClick}) => {
    return (
        <div className={styles.chain_payment_method} onDoubleClick={onDoubleClick}>

            <div className={styles.payment_block}>

                <div className={styles.chain_token_line}>
                    <Chain iconUrl={chainIconUrl}>{chain}</Chain>
                    <Token iconUrl={tokenIconUrl}>{token}</Token>
                </div>
                <CopyBlock textToCopy={merchantAddress} defaultText="Скопировать адрес" copiedText="Скопировано"/>
            </div>
        </div>
    )
}

export default ChainPaymentMethod

