import {FC, SVGProps} from "react";


const UserNavIcon: FC<SVGProps<SVGSVGElement>> = (
    {width, height, className}
) => (
    <svg className={className} width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M16.3332 18.25C16.3332 15.7187 13.0499 13.6667 8.99984 13.6667C4.94975 13.6667 1.6665 15.7187 1.6665 18.25M8.99984 10.9167C6.46853 10.9167 4.4165 8.86464 4.4165 6.33333C4.4165 3.80203 6.46853 1.75 8.99984 1.75C11.5311 1.75 13.5832 3.80203 13.5832 6.33333C13.5832 8.86464 11.5311 10.9167 8.99984 10.9167Z"
            stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

)

export default UserNavIcon