import {useRef} from "react";
import {DialogHandles} from "./dialogTypes";

export const useControlDialog = () => {

    const ref = useRef<DialogHandles>(null);

    const handleOpenDialog = () => {
        ref.current?.handleOpenDialog()
    }

    const handleCloseDialog = () => {
        ref.current?.handleCloseDialog()
    }


    return {ref, handleOpenDialog, handleCloseDialog}
}