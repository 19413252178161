import styles from './AuthInput.module.scss'
import {forwardRef, HTMLAttributes} from 'react'
import { BiHide, BiShow } from "react-icons/bi";
import classNames from 'classnames';

interface IAuthInputProps extends HTMLAttributes<HTMLInputElement> {
    onIconClick?: () => void
    isShown?: boolean
    isWithIcon?: boolean
    type?: 'password' | 'email' | 'text' | 'number'
    title: string
    error?: string
    placeholder?: string
    autoComplete?: string
}

const AuthInput = forwardRef<HTMLInputElement, IAuthInputProps>(
({
     className,
     title,
     type,
     isShown,
     error,
     isWithIcon,
     onIconClick,
     ...props
 }, ref) => {

    const inputStyles = classNames(styles.input, {
        [styles.input_error]: error !== undefined
    })

    return (
        <div className={`${styles.wrapper} ${className}`}>
            <p className={styles.title}>{title}</p>
            <div className={styles.input_container}>
                <input className={inputStyles} spellCheck="false" type={type} ref={ref} {...props}/>
                {
                    isWithIcon && (
                            isShown
                            ? <BiShow className={styles.eye_icon} onClick={onIconClick}/>
                            : <BiHide className={styles.eye_icon} onClick={onIconClick}/>
                    )
                }
            </div>
            {error && <p className={styles.error}>{error}</p>}
        </div>
    )

})

export default AuthInput
