import {Dispatch, FC, SetStateAction, useState} from "react";

interface CopyNotificationProps {
    showNotification: boolean;
    setShowNotification?: Dispatch<SetStateAction<boolean>>
}

const CopyNotification:FC<CopyNotificationProps> = ({showNotification}) => {
    const [position] = useState({ x: 0, y: 0 });

    return (
        <>
            {showNotification && (
                <div
                    style={{
                        position: "fixed",
                        top: position.y + 20, // немного ниже курсора
                        left: position.x + 20, // немного правее курсора
                        padding: "5px 10px",
                        backgroundColor: "#4CAF50",
                        color: "white",
                        borderRadius: "5px",
                        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
                        fontSize: "14px",
                        pointerEvents: "none",
                        zIndex: 1000,
                    }}
                >
                    Скопировано!
                </div>
            )}
        </>
    );
};

export default CopyNotification;