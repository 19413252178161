import React, {
    forwardRef,
    useImperativeHandle,
    useRef,
    Ref,
    useState,
} from 'react';
import classNames from 'classnames';
import styles from './Dialog.module.scss';
import {useBodyScrollLock} from "../../shared/hooks/useBodyScrollLock";
import {useOnClickOutside} from "usehooks-ts";

interface DialogProps {
    onOpen?: () => void;
    onClose?: () => void;
    className?: string;
    children: React.ReactNode;
}

export interface DialogHandles {
    dialogRef: Ref<HTMLDivElement>;
    dialogBackdropRef: Ref<HTMLDivElement>;
    isDialogOpen: boolean;
    handleOpenDialog: () => void
    handleCloseDialog: () => void
}

const Dialog = forwardRef<DialogHandles, DialogProps>(({onOpen, onClose, className, children }, ref) => {
    const [isDialogOpen, setDialogOpen] = useState(false);
    const [lockScroll, unlockScroll] = useBodyScrollLock()

    const dialogRef = useRef<HTMLDivElement | null>(null)
    const dialogBackdropRef = useRef<HTMLDivElement | null>(null)

    const handleOpenDialog = () => {
        lockScroll()
        setDialogOpen(true);
        if (onOpen) onOpen()
    }

    const handleCloseDialog = () => {
        unlockScroll()
        setDialogOpen(false);
        if (onClose) onClose()
    }

    useOnClickOutside(dialogRef, (event) => {
        if (event.target !== dialogBackdropRef.current) return
        handleCloseDialog()
    });

    useImperativeHandle(ref, () => ({
        dialogRef,
        dialogBackdropRef,
        isDialogOpen,
        handleOpenDialog,
        handleCloseDialog,
    }), [isDialogOpen, handleCloseDialog, handleOpenDialog]);

    return (
        <>
            {isDialogOpen && (
                <div
                    ref={dialogBackdropRef}
                    className={classNames(styles.dialogBackdrop, {
                        [styles.open]: isDialogOpen,
                    })}
                >
                    <div
                        ref={dialogRef}
                        className={classNames(styles.dialog, className)} // Применяем дополнительные классы
                        role="dialog"
                        aria-modal="true"
                    >
                        <button
                            className={styles.closeButton}
                            onClick={handleCloseDialog}
                            aria-label="Close dialog"
                        >
                            &times;
                        </button>
                        {children}
                    </div>
                </div>
            )}
        </>
    )

});

export default Dialog;
