import {FC, SVGProps} from "react";

const ArrowIcon: FC<SVGProps<SVGSVGElement>> = (
    {width, height, className}
) => (
    <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16 10L12 14L8 10" stroke="white" strokeOpacity="0.7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
)

export default ArrowIcon
