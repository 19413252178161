import {FC, useState} from "react";
import styles from "./RegistrationForm.module.scss"
import AuthInput from "../../../../UI/AuthInput/AuthInput";
import {SubmitHandler, useForm} from "react-hook-form";
import {useMerchantAuthStore} from "../../store/merchantAuthStore/merchantAuthStore";
import {AxiosError} from "axios";
import {Link} from "react-router-dom";
import GradientButton from "../../../../UI/GradientButton/GradientButton";

interface Inputs {
    email: string
    password: string
    registrationKey: string
    isEmployee: boolean
}

const RegistrationForm: FC = () => {
    const [isShown, setShown] = useState({
        isPasswordShown: false
    })
    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
    } = useForm<Inputs>()

    const { registration, setMerchantAuthProcessing, isMerchantAuthProcessing } = useMerchantAuthStore((state) => ({
        registration: state.registration,
        isMerchantAuthProcessing: state.isMerchantAuthProcessing,
        setMerchantAuthProcessing: state.setMerchantAuthProcessing,
    }));

    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        try {
            await registration(data.email, data.password, data.registrationKey)
        } catch(e: any) {
            console.log(e)
            setMerchantAuthProcessing(false)
            if (e instanceof AxiosError) {
                const errData = e?.response?.data
                if (errData.message === 'Validation failed' && Array.isArray(errData.errors)) {
                    for (let error of errData.errors) {
                        setError(error.field as keyof Inputs, {message: error.message})

                    }
                }
                if (errData.message && errData.field) setError(errData.field, {message: errData.message})
            }
        }
    }

    const handlePasswordIconClick = () => setShown((prev) => ({...prev, isPasswordShown: !prev.isPasswordShown}))

    return (
        <form className={styles.registration_form} onSubmit={handleSubmit(onSubmit)}>
            <h1>Создать мерчант аккаунт</h1>
            <h3>Введите регистрационные данные</h3>

            <div className={styles.prompt}>
                <span>Уже есть аккаунт?</span>
                {/*<a href='#'>Нажмите, чтобы войти</a>*/}
                <Link to={'/login'}>Нажмите, чтобы войти</Link>
            </div>
            <AuthInput className={styles.auth_input}
                       type="email" title="Эл. почта"
                       {...register('email')}
                       error={errors.email?.message}
            />
            <AuthInput isWithIcon={true}
                       onIconClick={handlePasswordIconClick}
                       isShown={isShown.isPasswordShown}
                       className={styles.auth_input}
                       type={isShown.isPasswordShown ? 'text' : 'password'}
                       title="Пароль"
                       error={errors.password?.message}
                       {...register('password')}
            />

            <AuthInput isWithIcon={false}
                       isShown={true}
                       className={styles.auth_input}
                       type={'text'}
                       title="Ключ регистрации"
                       error={errors.registrationKey?.message}
                       {...register('registrationKey')}
            />

            <GradientButton disabled={isMerchantAuthProcessing} loading={isMerchantAuthProcessing} className={styles.btn}>Зарегистрироваться</GradientButton>
        </form>
    )

}

export default RegistrationForm