import Dialog from "../../../../UI/Dialog/Dialog";
import styles from "./AddPaymentMethodDialog.module.scss";
import { useBodyScrollLock } from "../../../../shared/hooks/useBodyScrollLock";
import Dropdown from "../../../../UI/Dropdown/Dropdown";
import { useOnClickOutside } from "usehooks-ts";
import {forwardRef, useEffect, useRef, useState} from "react";
import Input from "../../../../UI/Input/Input";
import GradientButton from "../../../../UI/GradientButton/GradientButton";
import {SubmitHandler, useForm} from "react-hook-form";
import { useMerchantPaymentMethodsStore } from "../../store/merchantPaymentsStore/merchantPaymentMethodsStore";
import LoaderCircle from "../../../../UI/LoaderCircle/LoaderCircle";
import AuthInput from "../../../../UI/AuthInput/AuthInput";
import {AxiosError} from "axios";
import {DialogHandles} from "../../../../UI/Dialog/dialogTypes";
import classNames from "classnames";

interface IAddPaymentMethodDialog {}

interface Inputs {
    common: string
}

interface TokenOption {
    label: string;
    value: string;
}

const AddPaymentMethodDialog = forwardRef<DialogHandles, IAddPaymentMethodDialog>((_, ref) => {
    const [selectedBip44CoinType, setSelectedBip44CoinType] = useState<number | null>(null);
    const [selectedCryptoPaymentMethodId, setSelectedCryptoPaymentMethodId] = useState<string | null>(null);
    const [tokenOptions, setTokenOptions] = useState<TokenOption[]>([]);

    const {
        paymentMethods,
        availableToAddPaymentMethods,
        getAvailableToAddPaymentMethods,
        isAvailableToAddPaymentMethodsRequesting,
        isPaymentMethodsAdding,
        setIsPaymentMethodsAdding,
        addPaymentMethod
    } = useMerchantPaymentMethodsStore((store) => ({
        paymentMethods: store.paymentMethods,
        availableToAddPaymentMethods: store.availableToAddPaymentMethods,
        isAvailableToAddPaymentMethodsRequesting: store.isAvailableToAddPaymentMethodsRequesting,
        getAvailableToAddPaymentMethods: store.getAvailableToAddPaymentMethods,
        isPaymentMethodsAdding: store.isPaymentMethodsAdding,
        setIsPaymentMethodsAdding: store.setIsPaymentMethodsAdding,
        addPaymentMethod: store.addPaymentMethod
    }));

    const { handleSubmit, formState: { errors }, clearErrors, setError } = useForm<Inputs>();

    const isButtonDisabled =
        !selectedBip44CoinType ||
        !selectedCryptoPaymentMethodId ||
        !tokenOptions.length;

    const targetRef = ref && 'current' in ref && ref.current ? ref.current : null

    const onSubmit: SubmitHandler<Inputs> = async (data, ) => {
        try {
            const selectedToken = tokenOptions.find(el => el.value === selectedCryptoPaymentMethodId)
            await addPaymentMethod({
                supportedTokenId: selectedToken?.value || '',
            })

            targetRef?.handleCloseDialog()
        } catch(e) {
            setIsPaymentMethodsAdding(false)
            if (e instanceof AxiosError) {
                const errData = e?.response?.data
                if (errData.message === 'Validation failed' && Array.isArray(errData.errors)) {
                    for (let error of errData.errors) {
                        setError(error.field, {message: error.message})
                    }
                }
                if (errData.message && errData.field) setError(errData.field, {message: errData.message})
            }
            console.log(e)
        }
    }

    useEffect(() => {
        const selectedChain = availableToAddPaymentMethods?.find(el => el.bip44CoinType === selectedBip44CoinType);
        if (selectedChain) {
            setTokenOptions(selectedChain.tokens?.map(token => ({
                label: token.tokenName,
                value: token.supportedTokenId,
            })) || []);
        } else {
            setTokenOptions([]);
        }
    }, [selectedBip44CoinType, availableToAddPaymentMethods]);

    const chainOptions = availableToAddPaymentMethods?.map(el => ({
        label: el.chainName,
        value: el.bip44CoinType,
    }));

    return isAvailableToAddPaymentMethodsRequesting ? (
        <Dialog ref={ref} className={styles.loading_dialog}>
            <LoaderCircle/>
        </Dialog>
    ) : isPaymentMethodsAdding ? (
        <Dialog ref={ref} className={styles.adding_dialog}>
            <h3>Добавляем новый способ оплаты</h3>
            <p>Это займет около минуты.</p>
            <div className={styles.flex}>
                <LoaderCircle/>
            </div>
        </Dialog>
    ) : !availableToAddPaymentMethods.length ? (
        <Dialog ref={ref} className={styles.no_available_payment_methods}>
            К сожалению, в данный момент нет доступных способов оплаты для добавления.
        </Dialog>
    ) : (
        <Dialog ref={ref} className={styles.dialog}>
            <form className={styles.form} onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <h3>Добавление нового способа оплаты</h3>
                <div className={styles.dialog_content}>
                    <div className={styles.dropdown_container}>
                        {/* Выбор сети */}
                        <div className={styles.dropdown_wrapper}>
                            <p>Выберите сеть</p>
                            <Dropdown
                                selectedOption={chainOptions?.find(option => option.value === selectedBip44CoinType) || null}
                                onSelect={(option) => {
                                    setSelectedBip44CoinType(option.value as number);
                                    setSelectedCryptoPaymentMethodId(null);
                                    clearErrors()
                                }}
                                placeholder="Сеть"
                                options={chainOptions}
                                className={styles.dropdown}
                            />
                        </div>

                        {/* Выбор токена */}
                        <div className={styles.dropdown_wrapper}>
                            <p>Выберите токен</p>
                            <Dropdown
                                selectedOption={tokenOptions.find(option => option.value === selectedCryptoPaymentMethodId) || null}
                                onSelect={(option) => {
                                    setSelectedCryptoPaymentMethodId(option.value as string);
                                }}
                                placeholder="Токен"
                                options={tokenOptions}
                                className={styles.dropdown}
                            />
                        </div>
                    </div>

                    {errors.common && <p className={classNames(styles.error, styles.common)}>{errors.common.message}</p>}

                    <GradientButton type="submit" disabled={isButtonDisabled} className={styles.add_btn}>
                        Добавить
                    </GradientButton>
                </div>
            </form>
        </Dialog>
    );

})

export default AddPaymentMethodDialog;