import {FC, ReactNode, useEffect} from "react";
import {useMerchantAuthStore} from "../store/merchantAuthStore/merchantAuthStore";

const MerchantAuthProvider: FC<{children?: ReactNode}> = ({children}) => {
    const {setAuthChecking, refreshTokens} = useMerchantAuthStore((state) => ({
        merchant: state.merchant,
        isAuthChecking: state.isAuthChecking,
        setAuthChecking: state.setAuthChecking,
        refreshTokens: state.refreshTokens,
    }));

    useEffect(() => {
        const checkAuth = async () => {
            try {
                const accessToken = localStorage.getItem('accessToken');
                if (accessToken) {
                    await refreshTokens();
                }
            } catch (error) {
                console.error('Error during token refresh:', error);
            } finally {
                setAuthChecking(false);
            }
        };

        checkAuth();
    }, [refreshTokens, setAuthChecking]);

    return <>{children}</>;
};

export default MerchantAuthProvider;
