import React, { forwardRef } from 'react';
import styles from './Input.module.scss';
import classNames from 'classnames';
import { HTMLAttributes, ForwardedRef } from "react";

interface IInputProps extends HTMLAttributes<HTMLInputElement> {
    readOnly?: boolean;
    disabled?: boolean;
    placeholder?: string;
    value?: string;
    type?: 'password' | 'email' | 'text' | 'number';
    autoComplete?: string;
    name?: string
}

const Input = forwardRef<HTMLInputElement, IInputProps>(({ className, ...props }, ref: ForwardedRef<HTMLInputElement>) => {
    return (
        <input
            ref={ref} // Пробрасываем ref
            spellCheck={false}
            className={classNames(styles.input, className)} // Объединяем стили
            type="text"
            {...props}
        />
    );
});

export default Input;
