import {useEffect, useState} from "react";
import {useCustomerPaymentSSEStore} from "../store/customerPaymentSSEStore/customerPaymentSSEStore";

interface IUsePaymentSSEEventsProps {
    paymentJwt: string
}

export const usePaymentSSEEvents = ({paymentJwt}: IUsePaymentSSEEventsProps) => {
    // const [paymentStatusSSE, setPaymentStatusSSE] = useState('');
    const [ listening, setListening ] = useState(false);

    const {paymentSSEStatus, setPaymentSSEStatus} = useCustomerPaymentSSEStore((store) => ({
        paymentSSEStatus: store.paymentSSEStatus,
        setPaymentSSEStatus: store.setPaymentSSEStatus
    }))

    useEffect(() => {
        if (!listening) {
            const eventSource = new EventSource(`${process.env.REACT_APP_API_BASE_URL}/customer/payment/events/${paymentJwt}`);

            // eventSource.onopen = (event) => {
            //     console.log('установлено соединение')
            // }

            eventSource.onmessage = (event) => {
                const newEvent = JSON.parse(event.data);
                setPaymentSSEStatus(newEvent.paymentStatus)
            };

            eventSource.onerror = (error) => {
                console.log('EventSource failed:', error);
                eventSource.close();
            };

            setListening(true);

            return () => {
                eventSource.close();
            };
        }
    }, []);

    return {paymentSSEStatus}
}