import { useState } from "react";

const useCopyToClipboard = () => {
    const [isCopied, setIsCopied] = useState(false);

    const copy = (text: string) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                setIsCopied(true);
                setTimeout(() => setIsCopied(false), 2000); // Сбрасываем состояние через 2 секунды
            })
            .catch(() => {
                setIsCopied(false);
            });
    };

    return { isCopied, copy };
};

export default useCopyToClipboard;
