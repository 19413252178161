import {FC, useEffect, useState} from "react";
import styles from "./ChangePasswordForm.module.scss";
import {Link, useNavigate} from "react-router-dom";
import AuthInput from "../../../../UI/AuthInput/AuthInput";
import {useForm} from "react-hook-form";
import {useMerchantAuthStore} from "../../store/merchantAuthStore/merchantAuthStore";
import {AxiosError} from "axios";
import GradientButton from "../../../../UI/GradientButton/GradientButton";

interface Inputs {
    password: string,
    newPassword: string,
    passwordConfirmation: string,
}

const ChangePasswordForm: FC = () => {
    const { register, handleSubmit, formState: { errors }, watch, setError, setValue } = useForm<Inputs>();
    const [isCurrPasswordIconShown, setCurrPasswordIconShown] = useState<boolean>(false)
    const [isNewPasswordIconShown, setNewPasswordIconShown] = useState<boolean>(false)
    const [isNewPasswordConfirmationIconShown, setNewPasswordConfirmationIconShown] = useState<boolean>(false)
    const [isSuccess, setSuccess] = useState(false)

    const navigate = useNavigate();


    const {changePassword, setPasswordChanging, isPasswordChanging, logout} = useMerchantAuthStore(store => ({
        changePassword: store.changePassword,
        setPasswordChanging: store.setPasswordChanging,
        isPasswordChanging: store.isPasswordChanging,
        logout: store.logout
    }));

    const currentPassword = watch('password')
    const newPassword = watch('newPassword')

    const onSubmit = async () => {
        try {
            await changePassword(currentPassword, newPassword)
            setSuccess(true)
        } catch (e) {
            setPasswordChanging(false)
            if (e instanceof AxiosError) {
                const errData = e?.response?.data
                if (errData.message === 'Validation failed' && Array.isArray(errData.errors)) {
                    for (let error of errData.errors) {
                        setError(error.field, {message: error.message})
                    }
                }
                if (errData.message && errData.field) setError(errData.field, {message: errData.message})
            }
            console.log(e)
        }
    }


    useEffect(() => {
        if (isSuccess) {
            logout().then(() => navigate('/dashboard'))
        }
    }, [isSuccess]);

    return (
        <form className={styles.login_form} onSubmit={handleSubmit(onSubmit)}>
            <h1>Изменение пароля</h1>
            <h3>Заполните форму</h3>

            <AuthInput isWithIcon={true}
                       className={styles.auth_input}
                       type={isCurrPasswordIconShown ? "text" : "password"}
                       title="Старый пароль"
                       isShown={isCurrPasswordIconShown}
                       onIconClick={() => setCurrPasswordIconShown(prev => !prev)}
                       error={errors.password?.message}
                       {...register('password')}
            />
            <AuthInput isWithIcon={true}
                       className={styles.auth_input}
                       type={isNewPasswordIconShown ? "text" : "password"}
                       title="Новый пароль"
                       isShown={isNewPasswordIconShown}
                       onIconClick={() => setNewPasswordIconShown(prev => !prev)}
                       error={errors.newPassword?.message}
                       {...register('newPassword')}
            />
            <AuthInput isWithIcon={true}
                       className={styles.auth_input}
                       type={isNewPasswordConfirmationIconShown ? "text" : "password"}
                       title="Подтвердите новый пароль"
                       isShown={isNewPasswordConfirmationIconShown}
                       onIconClick={() => setNewPasswordConfirmationIconShown(prev => !prev)}
                       error={errors.newPassword?.message}
                       {...register('passwordConfirmation')}
            />

            <GradientButton disabled={isSuccess} loading={isPasswordChanging} className={styles.btn}>
                {isSuccess ? 'Успешно' : 'Далее'}
            </GradientButton>
        </form>
    )
}

export default ChangePasswordForm;