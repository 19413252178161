import useCountdownTimer from "../../../../shared/hooks/useCountDown";
import {useNavigate} from "react-router-dom";
import {FC, useEffect} from "react";
import {useCustomerPaymentsStore} from "../../store/customerPaymentStore/customerPaymentStore";
import {useCustomerPaymentSSEStore} from "../../store/customerPaymentSSEStore/customerPaymentSSEStore";

interface ITimerProps {
    expirationDate: Date
}

const Timer: FC<ITimerProps> = ({expirationDate}) => {

    const navigate = useNavigate()
    const expiresAt = new Date(expirationDate);

    const {paymentPageData} = useCustomerPaymentsStore((store) => ({
        paymentPageData: store.paymentPageData,
    }))

    const {paymentSSEStatus} = useCustomerPaymentSSEStore((store) => ({
        paymentSSEStatus: store.paymentSSEStatus,
        setPaymentSSEStatus: store.setPaymentSSEStatus
    }))

    const currentTime = Date.now();
    const timeLeftInSeconds = Math.max(Math.floor((expiresAt.getTime() - currentTime) / 1000), 0);

    const countdown = useCountdownTimer(
        {
            initialTime: timeLeftInSeconds,
            onEnd: () => {
                navigate(0);
            }})

    useEffect(() => {
        if (paymentPageData!.paymentData.status !== 'fulfilled')
            countdown.toggleTimer()
    }, []);

    useEffect(() => {
        if (paymentPageData!.paymentData.status === 'fulfilled' || paymentSSEStatus === 'fulfilled')
            countdown.stopTimer()
    }, [paymentPageData!.paymentData.status, paymentSSEStatus]);

    return (
        <>{countdown.countdownTime}</>
    )
}


export default Timer