import styles from './InputWithTitle.module.scss'
import Input from "../Input/Input";
import {FC, forwardRef, HTMLAttributes} from "react";
import classNames from "classnames";

interface IInputWithTitleProps extends HTMLAttributes<HTMLInputElement>{
    type?: 'password' | 'email' | 'text' | 'number'
    symbol?: string
    inputTitle?: string
}

const InputWithTitle = forwardRef<HTMLInputElement, IInputWithTitleProps>(({className, inputTitle, type, symbol, ...args}, ref) => {
    return (
        <div className={classNames(styles.input_wrapper, className)}>
            <p className={styles.input_title}>{inputTitle}</p>
            <div className={styles.field_wrapper}>
                <span className={styles.symbol}>{symbol}</span>
                <Input type={type} className={styles.input} {...args} ref={ref}/>
            </div>
        </div>
    )
})

export default InputWithTitle;