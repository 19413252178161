import {FC, SVGProps} from "react";


const DashboardNavIcon: FC<SVGProps<SVGSVGElement>> = (
    {width, height, className}
) => (
    <svg className={className} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M3.6665 10.4979V15.4002C3.6665 16.4269 3.6665 16.9407 3.86633 17.3328C4.04209 17.6778 4.32235 17.958 4.66732 18.1338C5.05911 18.3334 5.57225 18.3334 6.597 18.3334H15.4027C16.4274 18.3334 16.9398 18.3334 17.3316 18.1338C17.6766 17.958 17.9578 17.6778 18.1335 17.3328C18.3332 16.9411 18.3332 16.4281 18.3332 15.4033V10.4979C18.3332 10.0081 18.3328 9.76302 18.2732 9.5351C18.2204 9.33312 18.1338 9.14198 18.0165 8.96925C17.8842 8.77435 17.7003 8.61272 17.3316 8.29017L12.9316 4.44017C12.2472 3.84133 11.9051 3.54206 11.5199 3.42817C11.1806 3.32782 10.8189 3.32782 10.4796 3.42817C10.0947 3.54197 9.75303 3.84096 9.06967 4.4389L4.66821 8.29017C4.29959 8.61272 4.11571 8.77435 3.9834 8.96926C3.86615 9.14198 3.77884 9.33312 3.72606 9.5351C3.6665 9.76303 3.6665 10.0081 3.6665 10.4979Z"
            stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>


)

export default DashboardNavIcon