import styles from "./CustomerPaymentPage.module.scss";
import QRCodeViewer from "../../../modules/customer/components/QRCodeViewer/QRCodeViewer";
import classNames from "classnames";
import {CgInfo} from "react-icons/cg";
import { BsCopy } from "react-icons/bs";
import {useCustomerPaymentsStore} from "../../../modules/customer/store/customerPaymentStore/customerPaymentStore";
import {getLogo} from "../../../shared/utils/getLogo";
import React, {useEffect, useRef, useState} from "react";
import {defaultQRCodeOptions} from "../../../modules/customer/constants/defaultQRCodeOptions";
import {Options} from "qr-code-styling";
import  {Option} from "../../../UI/Dropdown/Dropdown";
import GradientButton from "../../../UI/GradientButton/GradientButton";
import {getPaymentLink} from "../../../shared/utils/getPaymentLink";
import PaymentDetails from "../../../modules/customer/components/PaymentDetails/PaymentDetails";
import ChangePaymentMethodDialog
    from "../../../modules/customer/components/ChangePaymentMethodDialog/ChangePaymentMethodDialog";
import LoaderCircle from "../../../UI/LoaderCircle/LoaderCircle";
import {usePaymentSSEEvents} from "../../../modules/customer/hooks/usePaymentSSEEvents";
import {useLocation} from "react-router-dom";
import useCopyToClipboard from "../../../shared/hooks/useCopyToClipboard";
import CopyNotification from "../../../UI/CopyNotification/CopyNotification";
import {useControlDialog} from "../../../UI/Dialog/useControlDialog";

interface TokenOption {
    label: string;
    value: string;
}

const CustomerPaymentPage = () => {
    const [tokenIcon, setTokenIcon] = useState('')
    const [chainIcon, setChainIcon] = useState('')

    const [tempBip44CoinType, setTempBip44CoinType] = useState<number | null>(null);
    const [tempTokenAddress, setTempTokenAddress] = useState<string | null>(null)
    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);
    const {paymentSSEStatus} = usePaymentSSEEvents({paymentJwt: searchParams.get('id') || ''})

    const [selectedBip44CoinType, setSelectedBip44CoinType] = useState<number | null>(null);
    const [selectedTokenAddress, setSelectedTokenAddress] = useState<string | null>(null);

    const [tokenOptions, setTokenOptions] = useState<TokenOption[]>([]);
    const [qrOptions, setQrOptions] = useState<Options>({...defaultQRCodeOptions})
    const [showNotification, setShowNotification] = useState(false);

    const {copy} = useCopyToClipboard()
    const {ref, handleOpenDialog, handleCloseDialog} = useControlDialog()

    const {paymentPageData} = useCustomerPaymentsStore((store) => ({
        paymentPageData: store.paymentPageData,
        isPaymentDataLoading: store.isPaymentDataLoading,
        setPaymentDataLoading: store.setPaymentDataLoading,
        getPaymentData: store.getPaymentData
    }))

    useEffect(() => {
        const initializeData = async () => {
            if (!paymentPageData) return;

            const preferred = paymentPageData.merchantData.preferred;
            setSelectedBip44CoinType(preferred.bip44CoinType);
            setSelectedTokenAddress(preferred.token?.tokenAddress);
            setTempBip44CoinType(preferred.bip44CoinType);
            setTempTokenAddress(preferred.token?.tokenAddress);

            const tokenLogo = await getLogo({type: 'token', tokenOrChainName: preferred.token.tokenIconName});
            const chainLogo = await getLogo({type: 'chain', tokenOrChainName: preferred.chainIconName});

            setQrOptions(prev => ({
                ...prev,
                image: chainLogo,
                data: preferred.token?.tokenAddress
            }));
            setTokenIcon(tokenLogo);
            setChainIcon(chainLogo);
        }

        initializeData()
    }, [paymentPageData]);


    useEffect(() => {
        const loadTokenOptions = async () => {
            if (tempBip44CoinType !== null) {
                const selectedChain = paymentPageData?.merchantData.methods?.find(el => el.bip44CoinType === tempBip44CoinType);

                if (selectedChain) {
                    const options = selectedChain.tokens?.map(token => ({
                        label: token.tokenName,
                        value: token.tokenAddress,
                    })) || [];
                    setTokenOptions(options);
                    setTempTokenAddress(null);
                }
            }
        }

        loadTokenOptions();
    }, [tempBip44CoinType, paymentPageData]);

    const chainOptions = paymentPageData!.merchantData.methods?.map(el => ({
        label: el.chainName,
        value: el.bip44CoinType,
    }));

    const onChainSelect = (option: Option) => {
        setTempBip44CoinType(option.value as number);
        setTempTokenAddress(null);
    };

    const onTokenSelect = (option: Option) => {
        setTempTokenAddress(option.value as string);
    };

    const merchantData = paymentPageData!.merchantData

    const selectedChainData = merchantData.methods?.find(el => el.bip44CoinType === selectedBip44CoinType);
    const selectedTokenData = selectedChainData?.tokens?.find(el => el?.tokenAddress === selectedTokenAddress);

    const tempChainData = merchantData.methods?.find(el => el.bip44CoinType === tempBip44CoinType);
    const tempTokenData = tempChainData?.tokens?.find(el => el?.tokenAddress === tempTokenAddress);

    const onApplyClick = async () => {
        if (tempChainData && tempTokenData) {
            const chainLogo = await getLogo({type: 'chain', tokenOrChainName: tempChainData.chainIconName})
            const tokenLogo = await getLogo({type: 'token', tokenOrChainName: tempTokenData.tokenIconName})

            setQrOptions(prev => ({...prev, image: chainLogo, data: tempTokenData?.tokenAddress}))

            setTokenIcon(tokenLogo)
            setChainIcon(chainLogo)

            setSelectedBip44CoinType(tempBip44CoinType);
            setSelectedTokenAddress(tempTokenAddress);

            handleCloseDialog()
        }
    }

    const isApplyBtnDisabled = !tempChainData || !tempTokenData;
    const isPayedInterface = paymentSSEStatus === 'fulfilled' || paymentPageData!?.paymentData?.status === 'fulfilled';

    const handleCopyAddress = () => {
        copy(selectedChainData?.receiverAddress || '')

        setShowNotification(true)
        setTimeout(() => {
            setShowNotification(false)
        }, 1500)
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.payment_heading}>
                <div className={styles.payment_id_container}>
                    <div className={styles.payment_id_label}>ID оплаты:</div>
                    <div className={styles.payment_id}>{paymentPageData!.paymentData.id}</div>
                </div>
                <div>{!isPayedInterface && <LoaderCircle className={styles.loader_circle}/>}</div>
            </div>

            <PaymentDetails
                paymentAmount={paymentPageData!.paymentData.paymentAmountUSD}
                tokenName={selectedTokenData?.tokenName || ''}
                tokenIcon={tokenIcon}
                chainIcon={chainIcon}
                expirationDate={paymentPageData!.paymentData.expiresAt}
                isPayed={isPayedInterface}
            />

            <div className={styles.qr_info_container}>
                <QRCodeViewer className={classNames(styles.qr_code_viewer, isPayedInterface && styles.hidden_qr_code)} options={qrOptions} setOptions={setQrOptions} />
                <div className={styles.right_info_container}>
                    <div className={classNames(styles.info_container, styles.info, styles.recommendations_hide)}>
                        {isPayedInterface && <div className={styles.hidden_coverage}/>}
                        <div className={styles.lines}>
                            <CgInfo className={styles.info_icon}/>
                            <p>Отсканируйте QR-код или скопируйте адрес получателя для оплаты.</p>
                        </div>

                        <div className={styles.lines}>
                            <CgInfo className={styles.info_icon}/>
                            <p>Внимание! Отправка другой суммы приведет к незачету платежа.</p>
                        </div>
                    </div>

                    <div className={classNames(styles.info_container, styles.address)}>
                        {isPayedInterface && <div className={styles.hidden_coverage}/>}
                        {selectedChainData?.receiverAddress}
                        <BsCopy className={styles.copy_icon} onClick={handleCopyAddress} />
                    </div>
                </div>
            </div>

            <GradientButton
                disabled={isPayedInterface}
                onClick={handleOpenDialog}
                className={styles.btn_overrides}
            >
                {isPayedInterface ? 'Оплата совершена успешно': 'Изменить способ оплаты'}
            </GradientButton>

            <ChangePaymentMethodDialog
                ref={ref}
                chainOptions={chainOptions}
                tokenOptions={tokenOptions}
                selectedChainOption={chainOptions?.find(option => option.value === tempBip44CoinType) || null}
                selectedTokenOption={tokenOptions.find(option => option.value === tempTokenAddress) || null}
                onChainSelect={(option) => onChainSelect(option)}
                onTokenSelect={(option) => onTokenSelect(option)}
                onApply={onApplyClick}
                isApplyBtnDisabled={isApplyBtnDisabled}
            />

            <CopyNotification showNotification={showNotification} />
        </div>
    )
}

export default CustomerPaymentPage