import {FC, ReactNode, useEffect} from "react";
import {useCustomerPaymentsStore} from "../store/customerPaymentStore/customerPaymentStore";
import {useLocation, useNavigate} from "react-router-dom";
import Loader from "../../../UI/Loader/Loader";

const RequireValidPaymentData: FC<{children: ReactNode}> = ({children}) => {
    const {paymentPageData, isPaymentDataLoading, setPaymentDataLoading, getPaymentData} = useCustomerPaymentsStore((store) => ({
        paymentPageData: store.paymentPageData,
        isPaymentDataLoading: store.isPaymentDataLoading,
        setPaymentDataLoading: store.setPaymentDataLoading,
        getPaymentData: store.getPaymentData
    }))

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const fallback = () => {
            navigate('/not-found');
        }

        const fetchPaymentData = async () => {
            try {
                const searchParams = new URLSearchParams(location.search);
                const id = searchParams.get('id');

                if (id) await getPaymentData(id);
                else fallback()
            } catch(error) {
                console.log({error})
                fallback()
            } finally {
                setPaymentDataLoading(false)
            }
        }

        fetchPaymentData()
    }, [location.search, navigate]);

    if (isPaymentDataLoading || !paymentPageData) return <Loader />

    return (
        <>{children}</>
    )

}

export default RequireValidPaymentData;