import styles from './NotFoundPage.module.scss'
import whalerpayLogo from "../../../assets/whalerpay_logo.svg";

const NotFoundPage = () => {
    return (
        <>
            <header className={styles.header}>
                <img src={whalerpayLogo} alt='whalerpayLogo' className={styles.logo}/>
            </header>
            <main className={styles.main}>
                <h1>404</h1>
                <h2>Страница не найдена</h2>
            </main>
            <footer className={styles.footer}></footer>
        </>
    )
}

export default NotFoundPage;