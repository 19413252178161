import styles from "./ChangePasswordPage.module.scss";
import whalerpayLogo from "../../../assets/whalerpay_logo.svg";
import ChangePasswordForm from "../../../modules/merchant/components/ChangePasswordForm/ChangePasswordForm";

const ChangePasswordPage = () => {
    return (
        <>
            <header className={styles.header}>
                <img src={whalerpayLogo} alt='whalerpayLogo' className={styles.logo}/>
            </header>
            <main className={styles.main}>
                <ChangePasswordForm />
            </main>
            <footer className={styles.footer}/>
        </>
    )
}

export default ChangePasswordPage;