import { createContext, FC, ReactNode, useState } from 'react'

interface ThemeContextProviderProps {
    children: ReactNode
}

type Theme = 'light' | 'dark'

interface ThemeContextProps {
    theme: 'light' | 'dark',
    setTheme: (theme: Theme) => void,
}

const defaultContextValue: ThemeContextProps = {
    theme: 'dark',
    setTheme: () => {}, // Пустая функция, её можно переопределить в провайдере
};

export const ThemeContext = createContext<ThemeContextProps>(defaultContextValue)

const ThemeContextProvider: FC<ThemeContextProviderProps> = ({children}) => {

    const [theme, setTheme] = useState<Theme>('dark')

    return <ThemeContext.Provider value={{theme, setTheme}}>
        {children}
    </ThemeContext.Provider>
}

export default ThemeContextProvider