// useCountdownTimer.ts
import { useEffect, useState } from "react";

interface UseCountdownTimerProps {
    initialTime: number; // Время в секундах
    onEnd: () => void;   // Функция, вызываемая по окончании таймера
}

const useCountdownTimer = ({ initialTime, onEnd }: UseCountdownTimerProps) => {
    const [timeLeft, setTimeLeft] = useState<number>(initialTime);
    const [isRunning, setIsRunning] = useState<boolean>(false);

    useEffect(() => {
        let interval: NodeJS.Timeout | null = null;

        if (isRunning && timeLeft > 0) {
            interval = setInterval(() => {
                setTimeLeft(prevTime => {
                    if (prevTime <= 1) {
                        clearInterval(interval!);
                        setIsRunning(false);
                        onEnd();
                        return 0;
                    }
                    return prevTime - 1;
                });
            }, 1000); // Обновление каждую секунду
        }

        return () => {
            if (interval) clearInterval(interval);
        };
    }, [isRunning, timeLeft, onEnd]);

    const toggleTimer = () => {
        setIsRunning(prev => !prev);
    };

    const stopTimer = () => {
        setIsRunning(false);
        setTimeLeft(0)
    }

    const formatTime = (time: number): string => {
        const hours = Math.floor(time / 3600);
        const minutes = Math.floor((time % 3600) / 60);
        const seconds = time % 60;

        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };

    const formattedTime = formatTime(timeLeft);

    return {
        countdownTime: formattedTime,
        toggleTimer,
        stopTimer
    };
};

export default useCountdownTimer;
