import React, { useState } from 'react';
import classNames from 'classnames';
import styles from './Dropdown.module.scss'; // Импортируем SCSS как модуль
import ArrowIcon from '../Icons/ArrowIcon';

export interface Option {
    label: string; // Текст, который будет виден в списке
    value: string | number; // Значение, которое будет использоваться для выбора
}

interface DropdownProps {
    options: Option[]; // Массив опций
    onSelect: (value: Option) => void; // Функция для обработки выбора
    placeholder?: string; // Placeholder для кнопки
    className?: string; // Дополнительные классы для стилизации
    selectedOption: Option | null; // Текущая выбранная опция
}

const Dropdown: React.FC<DropdownProps> = ({
                                               options,
                                               onSelect,
                                               placeholder = 'Select an option',
                                               className,
                                               selectedOption,
                                           }) => {
    const [isDropped, setDropped] = useState(false);

    const handleSelect = (option: Option) => {
        onSelect(option); // Передаем выбранное значение наверх через onSelect
        setDropped(false); // Закрываем список после выбора
    };

    return (
        <div className={classNames(styles.dropdown, className)}>
            <button
                type="button"
                className={styles.dropdownButton}
                onClick={() => setDropped(!isDropped)}
                aria-expanded={isDropped}
            >
                <span>{selectedOption ? selectedOption.label : placeholder}</span> {/* Отображаем выбранный label */}
                <span className={classNames(styles.arrow, { [styles.open]: isDropped })}>
                    <ArrowIcon />
                </span>
            </button>

            {isDropped && (
                <ul className={styles.dropdownMenu}>
                    {options?.map((option, index) => (
                        <li
                            key={index}
                            className={styles.dropdownItem}
                            onClick={() => handleSelect(option)} // При клике выбираем опцию
                        >
                            {option.label} {/* Отображаем label */}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default Dropdown;
