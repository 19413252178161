import {create} from "zustand";
import {MerchantProfilesService} from "../api/MerchantProfileService";
import {IMerchantChangeProfileDataRequest} from "../api/requests/IMerchantChangeProfileDataRequest";
import {MerchantType} from "../types/MerchantType";

interface IMerchantPaymentsStore {
    isProfileDataChanging: boolean;
    setProfileDataChanging: (state: boolean) => void
    changeProfileData: (props: IMerchantChangeProfileDataRequest) => Promise<MerchantType>
}

export const useMerchantProfileStore = create<IMerchantPaymentsStore>((set, get) => ({
    isProfileDataChanging: false,
    setProfileDataChanging: (state: boolean) => {
        set({isProfileDataChanging: state});
    },
    changeProfileData: async(props: IMerchantChangeProfileDataRequest) => {
        set({isProfileDataChanging: true})
        const res = await MerchantProfilesService.changeProfileData(props)

        set({isProfileDataChanging: false})

        return res.data
    }
}))