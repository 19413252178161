import {FC} from 'react'
import styles from './PanelPage.module.scss'
import Button from "../../../UI/Button/Button";
import GradientButton from "../../../UI/GradientButton/GradientButton";
import MerchantTable from "../../../modules/merchant/components/MerchantTable/MerchantTable";
import CreatePaymentLinkDialog
    from "../../../modules/merchant/components/CreatePaymentLinkDialog/CreatePaymentLinkDialog";
import {useControlDialog} from "../../../UI/Dialog/useControlDialog";

const PanelPage: FC = () => {
    const {ref, handleOpenDialog} = useControlDialog()

    return (
        <div className={styles.wrapper}>
            <div className={styles.btn_group}>
                <Button className={styles.btn}>История платежей</Button>
            </div>
            <div className={styles.content}>
                <div className={styles.content_group}>
                    <GradientButton
                        onClick={handleOpenDialog}
                        className={styles.gradient_btn}
                    >Создать платеж</GradientButton>
                </div>
                <MerchantTable />
            </div>

            <CreatePaymentLinkDialog
                ref={ref}
            />
        </div>
    )
}

export default PanelPage
