import styles from './RegistrationPage.module.scss'
import {FC} from 'react'
import whalerpayLogo from '../../../assets/whalerpay_logo.svg'
import {RegistrationForm} from "../../../modules/merchant";


const RegistrationPage: FC = () => {
    return (
        <>
            <header className={styles.header}>
                <img src={whalerpayLogo} alt='whalerpayLogo' className={styles.logo}/>
            </header>
            <main className={styles.main}>
                <RegistrationForm />
            </main>
            <footer className={styles.footer} />
        </>
    )

}

export default RegistrationPage
