import {create} from "zustand";
import {CustomerPaymentService} from "../../api/CustomerPaymentService";
import {PaymentPageData} from "./types/PaymentPageData";

interface ICustomerPaymentsStore {
    paymentPageData: PaymentPageData | null
    getPaymentData: (paymentJWT: string) => Promise<void>,
    isPaymentDataLoading: boolean
    setPaymentDataLoading: (isPaymentDataLoading: boolean) => void
}

export const useCustomerPaymentsStore = create<ICustomerPaymentsStore>((set, get) => ({
        paymentPageData: null,
        isPaymentDataLoading: true,
        getPaymentData: async (paymentJWT: string) => {
            const res = await CustomerPaymentService.getPaymentData(paymentJWT)

            set({paymentPageData: res.data, isPaymentDataLoading: false})
        },
        setPaymentDataLoading: (isPaymentDataLoading: boolean) => set({isPaymentDataLoading})
    }
))