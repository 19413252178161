import {FC} from "react";
import styles from "./ProfilePage.module.scss";
import GradientButton from "../../../UI/GradientButton/GradientButton";
import ChangeProfileDataDialog from "../../../modules/merchant/components/ChangeProfileDataDialog/ChangeProfileDataDialog";
import {useMerchantAuthStore} from "../../../modules/merchant/store/merchantAuthStore/merchantAuthStore";
import WithdrawDialog from "../../../modules/merchant/components/WithdrawDialog/WithdrawDialog";
import {useControlDialog} from "../../../UI/Dialog/useControlDialog";
import {
    useMerchantPaymentMethodsStore
} from "../../../modules/merchant/store/merchantPaymentsStore/merchantPaymentMethodsStore";

const ProfilePage: FC = () => {
    const {merchant, logout, isLoggingOut} = useMerchantAuthStore((state) => ({
        merchant: state.merchant,
        logout: state.logout,
        isLoggingOut: state.isLoggingOut
    }));

    const merchantEmail = (merchant?.email && merchant.email !== '') ? merchant?.email : 'не указано'
    const merchantName = (merchant?.name && merchant.name !== '') ? merchant.name : 'не указано'
    const merchantOrg = (merchant?.organizationDesc && merchant.organizationDesc !== '') ? merchant.organizationDesc : 'не указано'

    const {ref: withdrawalDialogHandlesRef, handleOpenDialog: handleOpenWithdrawalDialog} = useControlDialog()
    const {ref: changeProfileDataDialogRef, handleOpenDialog: handleOpenChangeProfileDataDialog} = useControlDialog()

    const {getBalances} = useMerchantPaymentMethodsStore((store) => ({
        balances: store.balances,
        getBalances: store.getBalances
    }))

    const handleWithdrawalButtonClick = async () => {
        handleOpenWithdrawalDialog()
        await getBalances()
    }

    return (
        <div className={styles.wrapper}>
            <h3>Настройки</h3>
            <div className={styles.profile_data_container}>

                <div className={styles.personal_data_container}>
                    <h3>Персональные данные</h3>
                    <ul>
                        <li>
                            <p>Email: {merchantEmail}</p>
                            <p>Пароль: надежно защищен</p>
                        </li>
                        <li>
                            <p>Имя: {merchantName}</p>
                            <p>Организация: {merchantOrg}</p>
                        </li>
                    </ul>

                    <div className={styles.gradient_btn_group}>
                        <GradientButton onClick={handleOpenChangeProfileDataDialog} className={styles.gradient_button}>Изменить данные</GradientButton>
                        <GradientButton onClick={handleWithdrawalButtonClick} className={styles.gradient_button}>Вывод криптовалюты</GradientButton>
                    </div>
                </div>

                <ChangeProfileDataDialog ref={changeProfileDataDialogRef} />

                <WithdrawDialog
                    ref={withdrawalDialogHandlesRef}
                />

                <GradientButton disabled={isLoggingOut} loading={isLoggingOut} className={styles.btn} onClick={logout}>Выйти из аккаунта</GradientButton>
            </div>

        </div>
    )
}

export default ProfilePage;