import styles from './DefaultButton.module.scss'
import {FC, HTMLAttributes} from "react";
import Button from "../Button/Button";

interface IDefaultButtonProps extends HTMLAttributes<HTMLButtonElement>{
    
}

const DefaultButton: FC<IDefaultButtonProps> = ({children, ...props}) => {
    return (
        <Button
            {...props}
            className={styles.default_button}
        >
            {children}
        </Button>
    )
}

export default DefaultButton;