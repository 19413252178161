import { Options } from 'qr-code-styling'

export const defaultQRCodeOptions: Options = {
    type: 'svg',
    width: 175,
    height: 175,
    data: '',
    margin: 0,
    qrOptions: { typeNumber: 0, mode: 'Byte', errorCorrectionLevel: 'Q' },
    imageOptions: { hideBackgroundDots: true, imageSize: 0.4, margin: 0 },
    dotsOptions: { type: 'square', color: '#ffffff', gradient: undefined },
    backgroundOptions: { color: 'none' },
    image: undefined,
    cornersSquareOptions: { type: 'square', color: '#ffffff' },
    cornersDotOptions: { type: 'square', color: '#ffffff' },
}