import styles from "./Token.module.scss";
import {FC} from "react";

interface ITokenProps {
    children?: string
    iconUrl?: string
}

const Token:FC<ITokenProps> = ({children, iconUrl}) => {
    return (
        <div className={styles.token}>
            <img src={iconUrl} alt="" className={styles.token_icon}/>
            {children}
        </div>
    )
}

export default Token