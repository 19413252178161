import {AxiosResponse} from "axios";
import $axios from "../config/http";
import {IAddPaymentMethodRequest} from "./requests/IAddPaymentMethodRequest";
import {PaymentMethodsResponse} from "./responses/PaymentMethodsResponse";
import {PreferredCryptoPaymentMethodResponse} from "./responses/PreferredCryptoPaymentMethodResponse";
import {GetPaymentsResponse} from "./responses/GetPaymentsResponse";
import {CreatePaymentResponse} from "./responses/CreatePaymentResponse";
import {MakePreferredResponse} from "./responses/MakePreferredResponse";
import {GetAvailableToAddPaymentMethodResponse} from "./responses/GetAvailableToAddPaymentMethodResponse";
import {GetBalancesResponse} from "./responses/GetBalancesResponse";

export class MerchantPaymentsService {
    static async getAvailableMethodsToAdd(): Promise<AxiosResponse<GetAvailableToAddPaymentMethodResponse>> {
        return $axios.get('/merchant/get-available-methods-to-add');
    }

    static async addPaymentMethod(dto: IAddPaymentMethodRequest): Promise<AxiosResponse<PaymentMethodsResponse>> {
        return $axios.post('/merchant/add-payment-method', dto);
    }

    static async getPaymentMethods(): Promise<AxiosResponse<PaymentMethodsResponse>> {
        return $axios.get('/merchant/get-payment-methods');
    }

    static async getAllPaymentMethods(): Promise<AxiosResponse<PaymentMethodsResponse>> {
        return $axios.get('/merchant/get-all-payment-methods');
    }

    static async getPreferredCryptoPaymentMethod(): Promise<AxiosResponse<PreferredCryptoPaymentMethodResponse>> {
        return $axios.get('/merchant/get-preferred-crypto-payment-method');
    }

    static async createPayment(paymentAmountUSD: number, productOrService: string): Promise<AxiosResponse<CreatePaymentResponse>> {
        return $axios.post('/merchant/create-payment', {
            paymentAmountUSD,
            productOrService
        });
    }

    static async getPayments(): Promise<AxiosResponse<GetPaymentsResponse>> {
        return $axios.get('/merchant/get-payments');
    }

    static async makePreferred(cryptoPaymentMethodId: string): Promise<AxiosResponse<MakePreferredResponse>> {
        return $axios.post('/merchant/make-preferred', {
            cryptoPaymentMethodId
        });
    }

    static async withdrawFunds(receiverAddress: string, password: string, bip44CoinType: number, tokenAddress: string): Promise<AxiosResponse<void>> {
        return $axios.post('/merchant/withdraw-funds', {
            receiverAddress,
            password,
            bip44CoinType,
            tokenAddress
        });
    }

    static async getBalances(): Promise<AxiosResponse<GetBalancesResponse>> {
        return $axios.get('/merchant/get-balances');
    }
}