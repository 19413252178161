import {forwardRef, useState} from "react";
import Dialog from "../../../../UI/Dialog/Dialog";
import styles from "./CreatePaymentLinkDialog.module.scss";
import {useForm} from "react-hook-form";
import InputWithTitle from "../../../../UI/InputWithTitle/InputWithTitle";
import GradientButton from "../../../../UI/GradientButton/GradientButton";
import {useMerchantPaymentMethodsStore} from "../../store/merchantPaymentsStore/merchantPaymentMethodsStore";
import {MerchantPayment} from "../../types/MerchantPayment";
import CopyBlock from "../CopyBlock/CopyBlock";
import {generatePaymentLink} from "../../utils/generatePaymentLink";
import {AxiosError} from "axios";
import {DialogHandles} from "../../../../UI/Dialog/dialogTypes";

export interface CreatePaymentLinkDialogProps {
    onClose?: () => void;
}

interface Inputs {
    paymentAmountUSD: number,
    productOrService: string
}

const CreatePaymentLinkDialog = forwardRef<DialogHandles, CreatePaymentLinkDialogProps>(({onClose}, ref) => {
    const {watch, handleSubmit, register, setError, formState: {errors}, setValue} = useForm<Inputs>()
    const [createdPayment, setCreatedPayment] = useState<null | MerchantPayment>()
    const [paymentLink, setPaymentLink] = useState('')

    const {createPayment, isPaymentCreating, setPaymentCreating} = useMerchantPaymentMethodsStore((store) => ({
        setPaymentCreating: store.setPaymentCreating,
        createPayment: store.createPayment,
        isPaymentCreating: store.isPaymentCreating
    }))

    const onSubmit = async (paymentAmountUSD: number, productOrService: string) => {
        try {
            if (paymentAmountUSD) {
                const payment = await createPayment(Number(paymentAmountUSD), productOrService)

                setCreatedPayment(payment)
                setPaymentLink(generatePaymentLink(payment.paymentJWTToken))
            }
        } catch (e) {
            setPaymentCreating(false)
            if (e instanceof AxiosError) {
                const errData = e?.response?.data
                if (errData.message === 'Validation failed' && Array.isArray(errData.errors)) {
                    for (let error of errData.errors) {
                        setError(error.field, {message: error.message})
                    }
                }
                if (errData.message && errData.field) setError(errData.field, {message: errData.message})
            }
            console.log(e)
        }
    }

    const paymentAmountUSD = watch('paymentAmountUSD')
    const productOrService = watch('productOrService')

    const handleDialogClose = () => {
        setCreatedPayment(null)
        setPaymentLink('')
        setValue('productOrService', '')
        if (onClose) onClose()
    }

    const isButtonDisabled = isPaymentCreating || !paymentAmountUSD || paymentAmountUSD <= 0

    return (
        <Dialog ref={ref} onClose={handleDialogClose} className={styles.dialog}>
            <form onSubmit={handleSubmit(() => onSubmit(paymentAmountUSD, productOrService))} className={styles.form}>
                {!createdPayment && (
                  <>
                      <InputWithTitle
                          {...register('paymentAmountUSD')}
                          symbol="$"
                          type="number"
                          inputTitle="Сумма в USD *"
                          className={styles.input}
                      />
                      {errors.paymentAmountUSD && <p className={styles.error}>{errors?.paymentAmountUSD.message}</p>}
                      <InputWithTitle
                          {...register('productOrService')}
                          inputTitle="Продукт или услуга"
                          className={styles.input}
                      />
                      {errors.productOrService && <p className={styles.error}>{errors?.productOrService.message}</p>}
                  </>
                )}
                {createdPayment && <CopyBlock textToCopy={paymentLink} defaultText="Копировать ссылку" copiedText="Скопировано !" className={styles.copy_link}/>}
                {!createdPayment && <GradientButton disabled={isButtonDisabled} loading={isPaymentCreating} className={styles.gradient_button} type="submit">Создать платеж</GradientButton>}
            </form>
        </Dialog>
    )
})

export default CreatePaymentLinkDialog;