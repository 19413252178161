import React from "react";
import styles from "./PaymentDetails.module.scss";
import classNames from "classnames";
import ClockIcon from "../../../../UI/Icons/ClockIcon";
import Timer from "../Timer/Timer";
import success_icon from '../../../../assets/success.svg'
interface PaymentDetailsProps {
    paymentAmount: number;
    tokenName: string;
    tokenIcon: string;
    chainIcon: string;
    expirationDate: Date;
    isPayed: boolean;
}

const PaymentDetails: React.FC<PaymentDetailsProps> = ({
                                                           paymentAmount,
                                                           tokenName,
                                                           tokenIcon,
                                                           chainIcon,
                                                           expirationDate,
                                                           isPayed
                                                       }) => (
        <div className={classNames(styles.info_container, styles.payment_details_container)}>
            {isPayed && (
                <>
                    <div className={styles.hidden_coverage}/>
                    <img src={success_icon} alt={success_icon}
                         className={classNames(styles.success_icon, styles.visible)}/>
                </>
            )}
            <div className={styles.injected_content_with_heading}>
                <p>Итого к оплате</p>
                <p>
                    {paymentAmount} {tokenName}
                    <img src={tokenIcon} alt="Token Icon" className={styles.chain_token_icon}/>
                    <img src={chainIcon} alt="Chain Icon" className={styles.chain_token_icon}/>
                </p>
            </div>
            <div className={styles.injected_content_with_heading}>
                <p>Период действия</p>
                <div className={styles.clock_wrapper}>
                    <ClockIcon className={styles.clock_icon}/>
                    <Timer expirationDate={expirationDate}/>
                </div>
            </div>
        </div>
);

export default PaymentDetails;
