import styles from './MerchantPanelNavbar.module.scss';
import {NavLink, NavLinkRenderProps, useLocation} from "react-router-dom";
import NotificationBellNavIcon from "../../../../UI/Icons/NotificationBellNavIcon";
import DashboardNavIcon from "../../../../UI/Icons/DashboardNavIcon";
import UserNavIcon from "../../../../UI/Icons/UserNavIcon";

const MerchantPanelNavbar = () => {

    const location = useLocation();

    const handleActiveLinkStyle = ({isActive}:  NavLinkRenderProps) => {
        const base = styles.nav_link
        return isActive ? base + ` ${styles.active_link}` : base
    }

    const handleActiveLinkStyleDashboard = (props:  NavLinkRenderProps) => {
        if (location.pathname.endsWith('profile')) return styles.nav_link

        return handleActiveLinkStyle(props)
    }

    return (
        <nav className={styles.navbar}>
            <NavLink to={'/dashboard'} className={handleActiveLinkStyleDashboard}><DashboardNavIcon className={styles.icon}/></NavLink>
            {/*<button className={styles.nav_link}><NotificationBellNavIcon className={styles.icon}/></button>*/}
            <NavLink to={'/dashboard/profile'} className={handleActiveLinkStyle}><UserNavIcon className={styles.icon}/></NavLink>
        </nav>
    )
}

export default MerchantPanelNavbar