import styles from './CustomerPaymentLayout.module.scss';
import whalerpayLogo from "../../../../../assets/whalerpay_logo.svg";
import DefaultButton from "../../../../../UI/DefaultButton/DefaultButton";
import {Outlet} from "react-router-dom";


const CustomerPaymentLayout = () => {
    return (
        <div className={styles.wrapper}>
            <header className={styles.header}>
                <img src={whalerpayLogo} alt='whalerpayLogo' className={styles.logo}/>
            </header>
            <main className={styles.main}>
                <div className={styles.top_buttons_group}>
                    <DefaultButton>Оплата заказа</DefaultButton>
                    {/*<DefaultButton>Разделение платежей</DefaultButton>*/}
                </div>
                <div className={styles.content_container}>
                    <Outlet />
                </div>
            </main>
            <footer className={styles.footer}/>
        </div>
    )
}

export default CustomerPaymentLayout;