
import styles from "./PaymentPage.module.scss";
import {useEffect, useState} from "react";
import LoaderCircle from "../../../UI/LoaderCircle/LoaderCircle";
import {useMerchantPaymentMethodsStore} from "../../../modules/merchant/store/merchantPaymentsStore/merchantPaymentMethodsStore";
import PreferredPaymentMethod from "../../../modules/merchant/components/PreferredPaymentMethod/PreferredPaymentMethod";
import {AddPaymentMethodDialog, AllPaymentMethods} from "../../../modules/merchant";
import {useControlDialog} from "../../../UI/Dialog/useControlDialog";

const MethodsPage = () => {

    const {ref, handleOpenDialog} = useControlDialog()
    const [isAddPaymentMethodDialogOpen, setAddPaymentMethodDialogOpen] = useState<boolean>(false);

    const {
        preferredPaymentMethod,
        getPaymentMethods,
        isPaymentMethodsRequesting,
        setIsPaymentMethodsRequesting,
        getAvailableToAddPaymentMethods
    } = useMerchantPaymentMethodsStore((store) => ({
        getAvailableToAddPaymentMethods: store.getAvailableToAddPaymentMethods,
        preferredPaymentMethod: store.preferredPaymentMethod,
        paymentMethods: store.paymentMethods,
        getPaymentMethods: store.getPaymentMethods,
        isPaymentMethodsRequesting: store.isPaymentMethodsRequesting,
        setIsPaymentMethodsRequesting: store.setIsPaymentMethodsRequesting
    }));

    useEffect(() => {
        getPaymentMethods().catch(e => {
            console.error(e)
            setIsPaymentMethodsRequesting(false)
        })
    }, []);

    useEffect(() => {
        if (isAddPaymentMethodDialogOpen) {
            getAvailableToAddPaymentMethods();
        }
    }, [isAddPaymentMethodDialogOpen]);

    const handleOpenAddPaymentMethodDialog = () => {
        setAddPaymentMethodDialogOpen((prev) => !prev)
        handleOpenDialog()
    }

    return (
        <div className={`${styles.wrapper} ${isPaymentMethodsRequesting ? styles.loading : ''}`}>
            {
                !isPaymentMethodsRequesting ? (
                    <>
                        {preferredPaymentMethod ? (
                            <>
                                <h3>Предпочтительный способ оплаты</h3>
                                <PreferredPaymentMethod onAddButtonClick={handleOpenAddPaymentMethodDialog}/>
                            </>
                        ): null}

                        <AddPaymentMethodDialog ref={ref} />

                        <h3 style={{marginTop: preferredPaymentMethod ? '2rem' : ''}}>Все способы оплаты</h3>
                        <AllPaymentMethods onAddButtonClick={handleOpenAddPaymentMethodDialog} />
                    </>
                ) : <LoaderCircle />
            }

        </div>
    )
}

export default MethodsPage;