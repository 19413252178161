import styles from './LoginPage.module.scss'
import {FC} from 'react'
import whalerpayLogo from '../../../assets/whalerpay_logo.svg'
import {LoginForm} from "../../../modules/merchant";


const LoginPage: FC = () => {
    return (
        <>
            <header className={styles.header}>
                <img src={whalerpayLogo} alt='whalerpayLogo' className={styles.logo}/>
            </header>
            <main className={styles.main}>
                <LoginForm />
            </main>
            <footer className={styles.footer} />
        </>
    )

}

export default LoginPage
