import styles from "./LoaderCircle.module.scss";
import {FC, HTMLAttributes} from "react";
import classNames from "classnames";

interface ILoaderCircleProps extends HTMLAttributes<HTMLButtonElement> {

}

const LoaderCircle:FC<ILoaderCircleProps> = ({className}) => {
    return (
        <div className={classNames(styles.loader, className)}>
            <svg width="50" height="50" viewBox="0 0 50 50">
                <circle
                    className={styles.circle}
                    cx="25"
                    cy="25"
                    r="20"
                    fill="none"
                    strokeWidth="5"
                />
            </svg>
        </div>
    )
}


export default LoaderCircle;