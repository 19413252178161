import styles from "./MerchantDashboardLayout.module.scss";
import whalerIcon from "../../../assets/whalerpay_logo.svg";
import {Outlet} from "react-router-dom";
import {ReactNode} from "react";
import {MerchantPanelNavbar} from "../../../modules/merchant";
import MerchantSidebar from "../../../modules/merchant/components/MerchantSidebar/MerchantSidebar";

const MerchantDashboardLayout = ({children}: {children?: ReactNode}) => {

    return (
        <div className={styles.wrapper}>
            <header className={styles.header}>
                <img src={whalerIcon} alt="whalerIcon" className={styles.logo}/>
                <MerchantPanelNavbar/>
            </header>
            <div className={styles.content}>
                <MerchantSidebar classNameOverridesAside={styles.overrides_hide} />
                <main className={styles.main_content}>
                    <MerchantSidebar
                        classNameOverridesAside={styles.overrides_show}
                        classNameOverridesNav={styles.overrides_nav} />
                    <Outlet />
                </main>
            </div>

            <footer className={styles.footer}></footer>
        </div>
    )
}

export default MerchantDashboardLayout