import styles from "./MerchantSidebar.module.scss";
import {NavLink, NavLinkRenderProps} from "react-router-dom";
import OrdersNavIcon from "../../../../UI/Icons/OrdersNavIcon";
import PaymentMethodsNavIcon from "../../../../UI/Icons/PaymentMethodsNavIcon";
import classNames from "classnames";
import {FC} from "react";

interface IMerchantSidebarProps {
    classNameOverridesNav?: string;
    classNameOverridesAside?: string
}

const MerchantSidebar:FC<IMerchantSidebarProps> = ({classNameOverridesAside, classNameOverridesNav}) => {

    const handleActiveLinkStyle = ({isActive}:  NavLinkRenderProps) => {
        const base = styles.aside_link
        return isActive ? base + ` ${styles.active_link}` : base
    }

    return (
        <aside className={classNames(styles.sidebar, classNameOverridesAside)}>
            <nav className={classNames(styles.side_navbar, classNameOverridesNav)}>
                <ul>
                    <li><NavLink to={'/dashboard/payments'} className={handleActiveLinkStyle}>
                        <OrdersNavIcon className={styles.nav_icon}/>
                        <span>Мои платежи</span>
                    </NavLink></li>
                    <li><NavLink to={'/dashboard/methods'} className={handleActiveLinkStyle}>
                        <PaymentMethodsNavIcon className={styles.nav_icon}/>
                        <span>Способы оплаты</span>
                    </NavLink></li>
                </ul>
            </nav>
        </aside>
    )
}

export default MerchantSidebar