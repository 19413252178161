import {create} from "zustand";
import {PreferredPaymentMethod} from "./types/PreferredPaymentMethod";
import {PaymentMethod} from "./types/PaymentMethod";
import {MerchantPayment} from "../../types/MerchantPayment";
import {MerchantPaymentsService} from "../../api/MerchantPaymentsService";
import {IAddPaymentMethodRequest} from "../../api/requests/IAddPaymentMethodRequest";
import {AvailableToAddPaymentMethod} from "./types/AvailableToAddPaymentMethod";
import {MerchantBalances} from "./types/MerchantBalances";

interface IMerchantPaymentsStore {
    paymentMethods: PaymentMethod[]
    balances: MerchantBalances
    preferredPaymentMethod: PreferredPaymentMethod | null,
    availableToAddPaymentMethods: AvailableToAddPaymentMethod[];
    isPaymentMethodsAdding: boolean
    setIsPaymentMethodsAdding: (state: boolean) => void,
    isPaymentMethodsRequesting: boolean,
    setIsPaymentMethodsRequesting: (state: boolean) => void,
    isAvailableToAddPaymentMethodsRequesting: boolean;
    getAvailableToAddPaymentMethods: () => Promise<void>
    addPaymentMethod: (dto: IAddPaymentMethodRequest) => Promise<void>
    getPaymentMethods: () => Promise<void>
    createPayment: (paymentAmountUSD: number, productOrService: string) => Promise<MerchantPayment>
    isPaymentCreating: boolean
    setPaymentCreating: (state: boolean) => void
    payments: Array<MerchantPayment>
    fetchPayments: () => Promise<void>
    isPaymentsFetching: boolean
    setPaymentsFetching: (state: boolean) => void
    getPayments: () => Promise<void>
    makePreferred: (cryptoPaymentMethodId: string) => Promise<void>
    isFundsWithdrawing: boolean
    setFundsWithdrawing: (state: boolean) => void
    withdrawFunds: (bip44CoinType: number, tokenAddress: string, receiverAddress: string, password: string) => Promise<void>
    getBalances: () => Promise<void>
    isGettingBalances: boolean
    setGettingBalances: (state: boolean) => void
}

export const useMerchantPaymentMethodsStore = create<IMerchantPaymentsStore>((set, get) => ({
    paymentMethods: [],
    payments: [],
    balances: [],
    preferredPaymentMethod: null,
    availableToAddPaymentMethods: [],
    isPaymentMethodsRequesting: true,
    isPaymentMethodsAdding: false,
    isPaymentCreating: false,
    isFundsWithdrawing: false,
    isGettingBalances: false,
    setFundsWithdrawing: (state: boolean) => {
        set({isFundsWithdrawing: state});
    },
    setGettingBalances: (state: boolean) => {
        set({isGettingBalances: state});
    },
    setPaymentCreating: (state: boolean) => {
        set({isPaymentCreating: state});
    },
    isPaymentsFetching: false,
    fetchPayments: async () => {
        set({isPaymentsFetching: true})
    },
    setIsPaymentMethodsAdding: (state: boolean) => {
        set({isPaymentMethodsAdding: state});
    },
    isAvailableToAddPaymentMethodsRequesting: true,
    setIsPaymentMethodsRequesting: (state: boolean) => {
        set({isPaymentMethodsAdding: state});
    },
    getAvailableToAddPaymentMethods: async() => {
        set({
            isAvailableToAddPaymentMethodsRequesting: true,
        });
        const res = await MerchantPaymentsService.getAvailableMethodsToAdd()

        set({
            isAvailableToAddPaymentMethodsRequesting: false,
            availableToAddPaymentMethods: res.data
        });
    },
    addPaymentMethod: async(dto: IAddPaymentMethodRequest) => {
        set({
            isPaymentMethodsAdding: true,
        });
        const res = await MerchantPaymentsService.addPaymentMethod(dto)

        set({
            paymentMethods: res.data.methodsGroupedByChain,
            isPaymentMethodsAdding: false,
        });

        await get().getPaymentMethods();
    },
    getPaymentMethods: async() => {
        set({isPaymentMethodsRequesting: true});
        const res = await MerchantPaymentsService.getPaymentMethods()

        set({
            isPaymentMethodsRequesting: false,
            paymentMethods: res.data.methodsGroupedByChain,
            preferredPaymentMethod: res.data.preferred
        });
    },
    createPayment: async (paymentAmountUSD: number, productOrService: string) => {
        set({isPaymentCreating: true})

        const res = await MerchantPaymentsService.createPayment(paymentAmountUSD, productOrService)

        const filtred = get().payments?.filter(el => el?.status === 'fulfilled' || new Date(el.expiresAt) > new Date())

        set({isPaymentCreating: false, payments: [res.data, ...filtred]})

        return res.data
    },
    setPaymentsFetching: (state: boolean) => {
        set({isPaymentsFetching: state})
    },
    getPayments: async() => {
        set({isPaymentsFetching: true});

        const res = await MerchantPaymentsService.getPayments()

        const sortedItems = res.data.sort((a: any, b: any) => {
            return new Date(b.expiresAt).getTime() - new Date(a.expiresAt).getTime();
        });

        set({payments: sortedItems, isPaymentsFetching: false})
    },
    makePreferred: async(cryptoPaymentMethodId: string) => {
        set({isPaymentMethodsRequesting: true})

        const res = await MerchantPaymentsService.makePreferred(cryptoPaymentMethodId)

        await new Promise(resolve => setTimeout(resolve, 200))

        set({isPaymentMethodsRequesting: false, preferredPaymentMethod: res.data})
    },
    withdrawFunds: async(bip44CoinType: number, tokenAddress: string, receiverAddress: string, password: string) => {
        set({isFundsWithdrawing: true})

        const res = await MerchantPaymentsService.withdrawFunds(
            receiverAddress,
            password,
            bip44CoinType,
            tokenAddress
        )

        set({isFundsWithdrawing: false})
    },
    getBalances: async () => {
        set({isGettingBalances: true})

        const res = await MerchantPaymentsService.getBalances()

        set({isGettingBalances: false, balances: res.data || []})
    }
}))