import styles from "./AllPaymentMethods.module.scss";
import { FC, useEffect, useState } from "react";
import ChainPaymentMethod from "../ChainPaymentMethod/ChainPaymentMethod";
import { useMerchantPaymentMethodsStore } from "../../store/merchantPaymentsStore/merchantPaymentMethodsStore";
import { PaymentMethod } from "../../store/merchantPaymentsStore/types/PaymentMethod";
import { AddPaymentMethodButton } from "../../index";
import {getLogo} from "../../../../shared/utils/getLogo";
import {PaymentMethodWithIcon} from "../../types/PaymentMethodWithIcon";

interface IAllPaymentMethodsProps {
    onAddButtonClick?: (...args: any[]) => void;
}

const AllPaymentMethods: FC<IAllPaymentMethodsProps> = ({ onAddButtonClick }) => {
    const { paymentMethods, makePreferred} = useMerchantPaymentMethodsStore((store) => ({
        paymentMethods: store.paymentMethods,
        makePreferred: store.makePreferred
    }));

    const [methodsWithIcons, setMethodsWithIcons] = useState<PaymentMethodWithIcon[]>([])

    useEffect(() => {
        const fetchLogos = async () => {
            const updatedMethods = await Promise.all(paymentMethods.map(async (method: PaymentMethod) => {
                return {
                    ...method,
                    chainIcon: await getLogo({
                        type: "chain",
                        tokenOrChainName: method.chainIconName || "",
                    }),
                    tokens: await Promise.all(
                        method.tokens.map(async(token) => ({
                            ...token,
                            tokenIcon: await getLogo({
                                type: "token",
                                tokenOrChainName: token.tokenIconName || ""
                            }),
                        }))
                    )
                }
            }));

            setMethodsWithIcons(updatedMethods);
        };

        fetchLogos();
    }, [paymentMethods]);

    return (
        <div className={styles.all_payment_methods_container}>
            {
                methodsWithIcons.map((method, methodIndex) => method.tokens.map(token => (
                        <ChainPaymentMethod
                            chainIconUrl={method.chainIcon}
                            tokenIconUrl={token.tokenIcon}
                            key={`${method.bip44CoinType}-${token.tokenAddress}-${methodIndex}`}
                            chain={method.chainName}
                            token={token.tokenName}
                            merchantAddress={token.tokenAddress}
                            onDoubleClick={() => makePreferred(token.paymentMethodId)}
                        />
                    ))
                )}

            {!paymentMethods.length && (
                <AddPaymentMethodButton onClick={onAddButtonClick}>
                    Добавить новый способ оплаты
                </AddPaymentMethodButton>
            )}
        </div>
    );
};

export default AllPaymentMethods;
