import styles from './PreferredPaymentMethod.module.scss'
import ChainPaymentMethod from "../ChainPaymentMethod/ChainPaymentMethod";
import {AddPaymentMethodButton} from "../../index";
import {FC, useEffect, useState} from "react";
import {useMerchantPaymentMethodsStore} from "../../store/merchantPaymentsStore/merchantPaymentMethodsStore";
import {getLogo} from "../../../../shared/utils/getLogo";

interface IPreferredPaymentMethodProps {
    onAddButtonClick: (...args: any[]) => void;
}

const PreferredPaymentMethod: FC<IPreferredPaymentMethodProps> = ({ onAddButtonClick }) => {
    const { preferredPaymentMethod, paymentMethods } = useMerchantPaymentMethodsStore(store => ({
        preferredPaymentMethod: store.preferredPaymentMethod,
        paymentMethods: store.paymentMethods
    }));

    const [chainIcon, setChainIcon] = useState<string>('');
    const [tokenIcon, setTokenIcon] = useState<string>('');

    useEffect(() => {
        const fetchLogos = async () => {
            const chainIconUrl = await getLogo({ type: 'chain', tokenOrChainName: preferredPaymentMethod?.chainIconName || '' });
            const tokenIconUrl = await getLogo({ type: 'token', tokenOrChainName: preferredPaymentMethod?.token.tokenIconName || '' });
            setChainIcon(chainIconUrl);
            setTokenIcon(tokenIconUrl);
        };

        fetchLogos();
    }, [preferredPaymentMethod, paymentMethods]);

    return (
        <div className={styles.preferred_payment_method_container}>
            <ChainPaymentMethod
                chain={preferredPaymentMethod?.chainName}
                token={preferredPaymentMethod?.token.tokenName}
                chainIconUrl={chainIcon}
                tokenIconUrl={tokenIcon}
                merchantAddress={preferredPaymentMethod?.token.tokenAddress}
            />
            <AddPaymentMethodButton onClick={onAddButtonClick}>Добавить новый способ оплаты</AddPaymentMethodButton>
        </div>
    );
}

export default PreferredPaymentMethod;
