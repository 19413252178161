interface IGetLogoProps {
    type: 'chain' | 'token'
    tokenOrChainName: string
}

export const getLogo = async ({ type, tokenOrChainName }: IGetLogoProps) => {
    try {
        const image = await import(`../../assets/crypto/${type}/${tokenOrChainName.toLowerCase()}.svg`);
        return image.default;
    } catch (error) {
        const defaultImage = await import('../../assets/crypto/default.svg');
        return defaultImage.default;
    }
};
