import {create} from "zustand";

type IPaymentSSEStatus =  'created' | 'partially-fulfilled' | 'fulfilled'

interface ICustomerPaymentSSEStore {
    paymentSSEStatus: IPaymentSSEStatus
    setPaymentSSEStatus: (paymentSSEStatus: IPaymentSSEStatus) => void
}

export const useCustomerPaymentSSEStore = create<ICustomerPaymentSSEStore>((set, get) => ({
    paymentSSEStatus: 'created',
    setPaymentSSEStatus: (paymentSSEStatus: IPaymentSSEStatus) => set({paymentSSEStatus: paymentSSEStatus})
    }
))