import {FC, SVGProps} from "react";

const PaymentMethodsNavIcon: FC<SVGProps<SVGSVGElement>> = (
    {width, height, className}
) => (
    <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21 12L12 18L3 12M21 16L12 22L3 16M21 8L12 14L3 8L12 2L21 8Z" stroke="white" strokeWidth="2"
              strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

)


export default PaymentMethodsNavIcon