import {forwardRef, HTMLAttributes} from 'react'
import styles from './Checkbox.module.scss'

interface ICheckboxProps extends HTMLAttributes<HTMLInputElement> {
    className?: string
}

const Checkbox = forwardRef<HTMLInputElement, ICheckboxProps>(({className, ...props}, ref) => {
    return (
        <label className={styles.custom_checkbox}>
            <input style={{
                opacity: '0',
                visibility: 'hidden',
                display: "none"
            }} type="checkbox" className={styles.target_input} ref={ref} {...props} />
                <span className={styles.checkmark} />
            Я сотрудник
        </label>
    )
})

export default Checkbox
