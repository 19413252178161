import React from 'react'
import './app.scss'
import {createBrowserRouter, Navigate, RouterProvider} from 'react-router-dom'
import PanelPage from './pages/merchant/PanelPage/PanelPage'
import LoginPage from "./pages/merchant/LoginPage/LoginPage";
import RegistrationPage from "./pages/merchant/RegistrationPage/RegistrationPage";
import MerchantDashboardLayout from "./UI/Layouts/MerchantDashboardLayout/MerchantDashboardLayout";
import ProfilePage from "./pages/merchant/ProfilePage/ProfilePage";
import {MerchantAuthProvider, RequireMerchantAuth} from "./modules/merchant";
import ChangePasswordPage from "./pages/merchant/ChangePasswordPage/ChangePasswordPage";
import CustomerPaymentLayout from "./modules/customer/UI/Layouts/CustomerPaymentLayout/CustomerPaymentLayout";
import CustomerPaymentPage from "./pages/customer/CustomerPaymentPage/CustomerPaymentPage";
import RequireValidPaymentData from "./modules/customer/HOCs/RequireValidPaymentData";
import NotFoundPage from "./UI/Pages/NotFoundPage/NotFoundPage";
import MethodsPage from "./pages/merchant/PaymentsPage/PaymentPage";

const router = createBrowserRouter([
    {
      path: '*',
      element: <NotFoundPage />
    },
    {
        path: '/login',
        element: (
            <RequireMerchantAuth>
                <LoginPage />
            </RequireMerchantAuth>
        ),
    },
    {
        path: '/change-password',
        element: (
            <RequireMerchantAuth>
                <ChangePasswordPage />
            </RequireMerchantAuth>
        ),
    },
    {
        path: '/registration',
        element: (
            <RequireMerchantAuth>
                <RegistrationPage />
            </RequireMerchantAuth>
        ),
    },
    {
        path: '/dashboard',
        element: (
            <RequireMerchantAuth>
                <MerchantDashboardLayout />
            </RequireMerchantAuth>
        ),
        children: [
            {
                index: true,
                element: <Navigate to="payments" />,
            },
            {
                path: 'payments',
                element: <PanelPage />
            },
            {
                path: 'profile',
                element: <ProfilePage />
            },
            {
                path: 'methods',
                element: <MethodsPage />
            },
        ]
    },
    {
        path: '/customer/payment',
        element: (
            <RequireValidPaymentData>
                <CustomerPaymentLayout />
            </RequireValidPaymentData>
        ),
        children: [
            {
                index: true,
                element: <CustomerPaymentPage />,
            }
        ]
    }
])

function App() {
    return (
        <MerchantAuthProvider>
            <RouterProvider router={router} />
        </MerchantAuthProvider>
    )
}

export default App
