import Button from "../Button/Button";
import styles from "./GradientButton.module.scss";
import classNames from "classnames";
import { FC, HTMLAttributes } from "react";

interface IGradientButtonProps extends HTMLAttributes<HTMLButtonElement> {
    disabled?: boolean;
    loading?: boolean;
    type?: 'reset' | 'submit' | 'button';
}

const GradientButton: FC<IGradientButtonProps> = ({ children, className, disabled, loading, ...props }) => {
    return (
        <Button
            className={classNames(styles.btn, className, {
                [styles.disabled]: disabled,
            })}
            disabled={disabled} // Чтобы HTML-элемент button также был отключен
            {...props}
        >
            {loading ? (
                    <div className={styles.loading}>
                        <span />
                        <span />
                        <span />
                    </div>
                ) : <>{children}</>
            }
        </Button>
    );
};

export default GradientButton;
