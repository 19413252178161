import Dialog from "../../../../UI/Dialog/Dialog";
import {forwardRef, useEffect} from "react";
import styles from "./ChangeProfileDataDialog.module.scss";
import AuthInput from "../../../../UI/AuthInput/AuthInput";
import GradientButton from "../../../../UI/GradientButton/GradientButton";
import {Link} from "react-router-dom";
import {useMerchantAuthStore} from "../../store/merchantAuthStore/merchantAuthStore";
import {useForm} from "react-hook-form";
import {useMerchantProfileStore} from "../../store/merchantProfileStore";
import LoaderCircle from "../../../../UI/LoaderCircle/LoaderCircle";
import {DialogHandles} from "../../../../UI/Dialog/dialogTypes";
import classNames from "classnames";

interface IChangeProfileDataDialog {}

interface Inputs {
    name: string;
    organizationDesc: string;
}

const ChangeProfileDataDialog = forwardRef<DialogHandles, IChangeProfileDataDialog>((_, ref) => {
    const {
        register,
        watch,
        handleSubmit,
        setValue,
    } = useForm<Inputs>()

    const {merchant, setMerchant} = useMerchantAuthStore((state) => ({
        merchant: state.merchant,
        setMerchant: state.setMerchant
    }));

    const {changeProfileData, isProfileDataChanging} = useMerchantProfileStore((store) => ({
        changeProfileData: store.changeProfileData,
        isProfileDataChanging: store.isProfileDataChanging
    }));

    const name = watch('name');
    const organizationDesc = watch('organizationDesc');
    const isDisabled =
        (!name && ! organizationDesc) ||
        (name === '' || organizationDesc === '') ||
        (name === merchant?.name && organizationDesc === merchant?.organizationDesc)

    const onSubmit = async (data: Inputs) => {
        try {
            const newMerchant = await changeProfileData(data);
            setMerchant(newMerchant)

            if (ref && 'current' in ref) ref.current?.handleCloseDialog()
        } catch(e) {
            console.log(e)
        }
    }

    useEffect(() => {
        if (merchant) {
            setValue('name', merchant.name)
            setValue('organizationDesc', merchant.organizationDesc)
        }
    }, []);

    return (
        <Dialog ref={ref} className={classNames(styles.dialog, isProfileDataChanging && styles.dialog_loading)}>
            <h3>Изменение данных профиля</h3>

            {isProfileDataChanging ? <LoaderCircle/> : <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                <AuthInput title="Имя" {...register('name')} />
                <AuthInput title="Название организации" {...register('organizationDesc')} />

                <Link to="/change-password" className={styles.change_password_link}>Сменить пароль</Link>

                <GradientButton
                    type="submit"
                    disabled={isDisabled}
                    className={styles.gradient_button}
                >
                    Сохранить
                </GradientButton>
            </form>}
        </Dialog>
    )
})

export default ChangeProfileDataDialog