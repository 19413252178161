import {IMerchantLoginRequest} from "./requests/IMerchantLoginRequest";
import {IMerchantRegistrationRequest} from "./requests/IMerchantRegistrationRequest";
import $axios from "../config/http";
import axios, {AxiosResponse} from "axios";
import {AuthResponse} from "./responses/AuthResponse";
import {CONFIG} from "../../../config/Config";
import {MerchantType} from "../types/MerchantType";

export class MerchantAuthService {
    static async login(data: IMerchantLoginRequest): Promise<AxiosResponse<AuthResponse>> {
        return $axios.post('/merchant/login', data);
    }

    static async registration(data: IMerchantRegistrationRequest): Promise<AxiosResponse<AuthResponse>> {
        return $axios.post('/merchant/register', data);
    }

    static async getMerchantData(): Promise<AxiosResponse<MerchantType>> {
        return $axios.get('/merchant/data')
    }

    static async refresh(): Promise<AxiosResponse<AuthResponse>> {
        return axios.get('/merchant/refresh', {withCredentials: true, baseURL: CONFIG.apiBaseUrl});
    }

    static async changePassword(password: string, newPassword: string): Promise<AxiosResponse> {
        return $axios.post('/merchant/change-password', {password, newPassword});
    }

    static async logout(): Promise<AxiosResponse> {
        return axios.get('/merchant/logout', {withCredentials: true, baseURL: CONFIG.apiBaseUrl});
    }
}