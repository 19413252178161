import styles from './QRCodeViewer.module.scss'
import {Dispatch, FC, SetStateAction, useEffect, useRef, useState} from 'react'
import QRCodeStyling, { Options } from 'qr-code-styling'
import { defaultQRCodeOptions } from '../../constants/defaultQRCodeOptions'
import classNames from "classnames";

const qrCode = new QRCodeStyling(defaultQRCodeOptions);

interface IQRCodeViewerProps {
    options: Options
    setOptions?: Dispatch<SetStateAction<Options>>
    className?: string
}

const QRCodeViewer:FC<IQRCodeViewerProps> = ({options, className}) => {
     const ref = useRef(null);

     useEffect(() => {
         if (ref.current) {
             qrCode.append(ref.current);
         }
     }, []);

     useEffect(() => {
         qrCode.update({
             ...options,
         });
     }, [options]);


     return (
             <div className={classNames(styles.wrapper, className)}>
                 <div ref={ref} className={styles.qr}/>
             </div>
     );
}


export default QRCodeViewer
