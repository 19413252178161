import {AxiosResponse} from "axios";
import $axios from "../config/http";
import {AuthResponse} from "./responses/AuthResponse";
import {IMerchantChangeProfileDataRequest} from "./requests/IMerchantChangeProfileDataRequest";
import {MerchantType} from "../types/MerchantType";

export class MerchantProfilesService {
    static async changeProfileData(data: IMerchantChangeProfileDataRequest): Promise<AxiosResponse<MerchantType>> {
        return $axios.post('/merchant/change-profile-data', data);
    }
}