import React, {forwardRef, useEffect, useRef, useState} from "react";
import Dialog, {DialogHandles} from "../../../../UI/Dialog/Dialog";
import styles from './WithdrawDialog.module.scss'
import Dropdown, {Option} from "../../../../UI/Dropdown/Dropdown";
import {useMerchantPaymentMethodsStore} from "../../store/merchantPaymentsStore/merchantPaymentMethodsStore";
import {PaymentMethod} from "../../store/merchantPaymentsStore/types/PaymentMethod";
import LoaderCircle from "../../../../UI/LoaderCircle/LoaderCircle";
import classNames from "classnames";
import GradientButton from "../../../../UI/GradientButton/GradientButton";
import {CgInfo} from "react-icons/cg";
import Input from "../../../../UI/Input/Input";
import AuthInput from "../../../../UI/AuthInput/AuthInput";
import {useForm} from "react-hook-form";
import {CoinTypeBalances} from "../../store/merchantPaymentsStore/types/MerchantBalances";
import {AxiosError} from "axios";

interface IWithdrawDialogProps {
    onClose?: () => void;
}

interface Inputs {
    receiverAddress: string;
    password: string
}

const WithdrawDialog = forwardRef<DialogHandles, IWithdrawDialogProps>(({onClose}, ref) => {
    const [isPasswordShown, setPasswordShown] = useState<boolean>(false);
    const {
        register,
        formState: { errors },
        setError,
        setValue,
        clearErrors,
        watch
    } = useForm<Inputs>()

    const {getPaymentMethods, setIsPaymentMethodsRequesting, balances, isGettingBalances, withdrawFunds, isFundsWithdrawing, setFundsWithdrawing} = useMerchantPaymentMethodsStore((store) => ({
        paymentMethods: store.paymentMethods,
        getPaymentMethods: store.getPaymentMethods,
        isPaymentMethodsRequesting: store.isPaymentMethodsRequesting,
        setIsPaymentMethodsRequesting: store.setIsPaymentMethodsRequesting,
        balances: store.balances,
        isGettingBalances: store.isGettingBalances,
        withdrawFunds: store.withdrawFunds,
        isFundsWithdrawing: store.isFundsWithdrawing,
        setFundsWithdrawing: store.setFundsWithdrawing
    }))

    const [selectedChainOption, setSelectedChainOption] = useState<Option | null>(null)
    const [selectedChainData, setSelectedChainData] = useState<CoinTypeBalances | null>(null)

    const [selectedTokenOption, setSelectedTokenOption] = useState<Option | null>(null)
    const [selectedTokenData, setSelectedTokenData] = useState<CoinTypeBalances['balances'][0] | null>()

    const chainOptions = balances.map(el => ({label: el.chainName, value: el.bip44CoinType}))
    const tokenOptions = selectedChainData?.balances.map(el => ({label: el.tokenName, value: el.tokenAddress}))

    useEffect(() => {
        if (selectedChainOption) {
            setSelectedChainData(balances.find(el => el.bip44CoinType === selectedChainOption.value) || null)
            setSelectedTokenOption(null)
            setSelectedTokenData(null)
        }
        setValue('password', '')
        setValue('receiverAddress', '')
        clearErrors('password')
        clearErrors('receiverAddress')
    }, [selectedChainOption])

    useEffect(() => {
        if (selectedChainData && selectedTokenOption) {
            setSelectedTokenData(selectedChainData.balances.find(el => el.tokenAddress === selectedTokenOption?.value))
        }
        setValue('password', '')
        setValue('receiverAddress', '')
        clearErrors('password')
        clearErrors('receiverAddress')
    }, [selectedTokenOption]);

    function handleCloseDialog() {
        setSelectedTokenData(null)
        setSelectedTokenOption(null)

        setSelectedChainOption(null)
        setSelectedChainData(null)

        setValue('password', '')
        setValue('receiverAddress', '')
        clearErrors('password')
        clearErrors('receiverAddress')

        if (onClose) onClose()
    }

    const passwordField = watch('password')
    const receiverAddressField = watch('receiverAddress')

    const isWithdrawButtonDisabled =
        !passwordField ||
        !receiverAddressField ||
        isFundsWithdrawing ||
        parseFloat(selectedTokenData?.balance || '0') <= 0

    const handleWithdraw = async () => {
        try {
            await withdrawFunds(
                selectedChainData?.bip44CoinType as number,
                selectedTokenData?.tokenAddress as string,
                receiverAddressField || '',
                passwordField || ''
            )
            handleCloseDialog()
        } catch(e) {
            setFundsWithdrawing(false)
            if (e instanceof AxiosError) {
                const errData = e?.response?.data
                if (errData.message === 'Validation failed' && Array.isArray(errData.errors)) {
                    for (let error of errData.errors) {
                        setError(error.field, {message: error.message})
                    }
                }
                if (errData.message && errData.field) setError(errData.field, {message: errData.message})
            }
            console.log(e)
        }
    }

    useEffect(() => {
        clearErrors("password");
    }, [passwordField, clearErrors]);

    useEffect(() => {
        clearErrors("receiverAddress");
    }, [receiverAddressField, clearErrors]);

    return isGettingBalances || isFundsWithdrawing ? (
        <Dialog ref={ref} className={classNames(styles.dialog, styles.loading_dialog)}>
            <LoaderCircle />
        </Dialog>
    ) : (
        <Dialog ref={ref} className={styles.dialog}
                onClose={handleCloseDialog}>
            <div
                className={styles.dropdown_container}
            >
                <div className={styles.dropdown_wrapper}>
                    <p>Выберите сеть</p>
                    <Dropdown
                        placeholder="Cеть"
                        className={styles.dropdown}
                        options={chainOptions}
                        onSelect={(option) => setSelectedChainOption(option)}
                        selectedOption={selectedChainOption}
                    />
                </div>
                <div className={styles.dropdown_wrapper}>
                    <p>Выберите токен</p>
                    <Dropdown
                        placeholder="Токен"
                        className={styles.dropdown}
                        options={tokenOptions || []}
                        onSelect={(option) => setSelectedTokenOption(option)}
                        selectedOption={selectedTokenOption}
                    />
                </div>
            </div>

            {selectedTokenData && selectedChainData && (
                <>
                    <div className={styles.info_block}>
                        <div className={styles.info_block_text}>
                            <CgInfo className={styles.info_icon}/>
                            <p>Дважды проверьте адрес кошелька, на который осуществляете вывод. При ошибке деньги
                                вернуть не получится.</p>
                        </div>
                    </div>

                    <div className={styles.btn_n_balance}>
                        <div className={styles.balance_window}>
                            {selectedTokenData ? (`${selectedTokenData.balance} ${selectedTokenData.tokenSymbol}`): ''}
                        </div>

                        <div className={styles.input_fields}>
                            <AuthInput
                                isWithIcon={false}
                                title="Адрес получателя"
                                type="text"
                                autoComplete="one-time-code"
                                error={errors.receiverAddress?.message}
                                {...register('receiverAddress')}
                            />
                            <AuthInput
                                title="Подтвердите действие паролем"
                                type={isPasswordShown ? 'text' : 'password'}
                                isShown={isPasswordShown}
                                isWithIcon={true}
                                onIconClick={() => setPasswordShown(!isPasswordShown)}
                                autoComplete="one-time-code"
                                error={errors.password?.message}
                                {...register('password')}
                            />
                        </div>


                        <GradientButton
                            onClick={handleWithdraw}
                            disabled={isWithdrawButtonDisabled}
                            loading={isFundsWithdrawing}
                            className={styles.gradient_button}
                        >
                            Вывод
                        </GradientButton>
                    </div>
                </>
            )}
        </Dialog>
    )
})

export default WithdrawDialog