import Dialog from "../../../../UI/Dialog/Dialog";
import styles from "./ChangePaymentMethodDialog.module.scss";
import Dropdown, {Option} from "../../../../UI/Dropdown/Dropdown";
import GradientButton from "../../../../UI/GradientButton/GradientButton";
import classNames from "classnames";
import {forwardRef} from "react";
import {DialogHandles} from "../../../../UI/Dialog/dialogTypes";

interface IChangePaymentMethodDialogProps {
    onClose?: () => void;
    onChainSelect: (option: Option) => void;
    onTokenSelect: (option: Option) => void;
    onApply: () => void;
    chainOptions: Option[] | null
    tokenOptions: Option[] | null
    selectedChainOption: Option | null
    selectedTokenOption: Option | null
    isApplyBtnDisabled: boolean
}

const ChangePaymentMethodDialog = forwardRef<DialogHandles, IChangePaymentMethodDialogProps>((
        {onClose, chainOptions, onChainSelect, tokenOptions, onTokenSelect, selectedChainOption, selectedTokenOption, onApply, isApplyBtnDisabled},
        ref
    ) => {
    return (
        <Dialog ref={ref} onClose={onClose} className={styles.dialog}>
            <div className={styles.dropdown_container}>
                <div className={styles.dropdown_wrapper}>
                    <p>Выберите сеть</p>
                    <Dropdown
                        selectedOption={selectedChainOption}
                        onSelect={(option) => onChainSelect(option)}
                        placeholder="Сеть"
                        options={chainOptions || []}
                        className={styles.dropdown}
                    />
                </div>

                <div className={styles.dropdown_wrapper}>
                    <p>Выберите токен</p>
                    <Dropdown
                        selectedOption={selectedTokenOption}
                        onSelect={(option) => onTokenSelect(option)}
                        placeholder="Токен"
                        options={tokenOptions || []}
                        className={styles.dropdown}
                    />
                </div>
            </div>

            <GradientButton disabled={isApplyBtnDisabled} onClick={onApply} className={classNames(styles.btn_overrides, styles.apply_btn)}>Применить</GradientButton>
        </Dialog>
    )
})

export default ChangePaymentMethodDialog