import styles from './CopyBlock.module.scss'
import {FC, HTMLAttributes, useState} from "react";

interface ICopyAddress extends HTMLAttributes<HTMLButtonElement>{
    textToCopy?: string
    defaultText: string
    copiedText: string
}

const CopyBlock:FC<ICopyAddress> = ({textToCopy, copiedText, defaultText, className}) => {
    const [isCopied, setCopied] = useState(false)
    let timeoutId: NodeJS.Timeout;

    const handleCopyText = (text: string) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                setCopied(true)
            })
            .catch((err) => {
                console.error("Ошибка при копировании текста: ", err);
            });
    }

    const handleMouseEnter = () => {
        clearTimeout(timeoutId);
    };

    const handleMouseLeave = () => {
        timeoutId = setTimeout(() => {
            setCopied(false);
        }, 200)
    }

    return (
        <button
            type="button"
            className={`${styles.merchant_address} ${isCopied ? styles.copied : ''} ${className}`}
            onClick={() => handleCopyText(textToCopy || '')}
            onMouseLeave={handleMouseLeave}
            onMouseEnter={handleMouseEnter}
        >
            {isCopied ? copiedText : defaultText}
        </button>
    )
}


export default CopyBlock