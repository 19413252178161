import styles from './AddPaymentMethodButton.module.scss';
import {FC, HTMLAttributes} from "react";

interface IAddPaymentMethodButtonProps extends HTMLAttributes<HTMLButtonElement>{}

const AddPaymentMethodButton:FC<IAddPaymentMethodButtonProps> = ({onClick, children}) => {
    return (
        <button className={styles.add_payment_method} onClick={onClick}>
            {children}
        </button>
    )
}


export default AddPaymentMethodButton