import {Navigate, useLocation} from "react-router-dom";
import {FC, ReactNode} from "react";
import {useMerchantAuthStore} from "../store/merchantAuthStore/merchantAuthStore";
import Loader from "../../../UI/Loader/Loader";

const RequireMerchantAuth: FC<{children: ReactNode}> = ({children}) => {
    const {merchant, isAuthChecking, isMerchantAuthProcessing} = useMerchantAuthStore(store => ({
        isMerchantAuthProcessing: store.isMerchantAuthProcessing,
        isAuthChecking: store.isAuthChecking,
        merchant: store.merchant
    }));

    const location = useLocation();

    if (isAuthChecking) {
        return <Loader />
    }

    if (merchant) {
        if (location.pathname === '/login' || location.pathname === '/registration') {
            return <Navigate to='/dashboard' />;
        }
    }

    if (!merchant && !isMerchantAuthProcessing) {
        if (location.pathname !== '/login' && location.pathname !== '/registration')
            return <Navigate to='/login' />;
    }

    return <>{children}</>;
}

export default RequireMerchantAuth;



