import styles from "./Chain.module.scss"
import {FC} from "react";

interface IChainProps {
    children?: string
    iconUrl?: string
}

const Chain:FC<IChainProps> = ({children, iconUrl}) => {

    return (
        <div className={styles.chain}>
            <img src={iconUrl} alt="" className={styles.chain_icon}/>
            {children}
        </div>
    )
}

export default Chain